import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { findIndex } from 'lodash';


export class Toast<C> {
  toastId: string;
  message: string;
  title: string;
  type: string;
  'icon-class': string;
  isActive: boolean;
  timeOutId;
  timer;
}

enum toastIcon {
  success = 'icon-check-mark-5-success',
  failure = 'icon-warning-8-failure',
  warning = 'icon-warning-2-warning',
  info = 'icon-info-2-functional-blue'
}

enum defaultToastTitle {
  success = 'Success',
  failure = 'Error',
  warning = 'Warning',
  info = 'Information'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor() { }
  toasts = [];
  toastChange: Subject<boolean> = new Subject<boolean>();

  toggleToastChange() {
    this.toastChange.next(!this.toastChange);
  }

  getToastIcon(type) {
    return toastIcon[type];
  }

  preToast(type, message?, title?) {
    const toast = new Toast<any>();
    toast.toastId = Math.random().toString(36).substring(2);
    toast['icon-class'] = toastIcon[type];
    toast.message = message;
    toast.title = (title || message) ? title : defaultToastTitle[type];
    toast.isActive = false;
    toast.type = type;
    return toast;
  }

  activateToast(toast) {
    this.toasts.splice(0, 0, toast);
    this.toggleToastChange();
  }

  removeToast(toast) {
    const idx = findIndex(this.toasts, (item) => {
      return item.toastId === toast.toastId;
    });
    if (idx < 0) { return; }
    setTimeout(() => {
      this.toasts.splice(idx, 1);
      this.toggleToastChange();
    }, 200);
  }

  success(message?, title?) {
    const toast = this.preToast('success', message, title);
    this.activateToast(toast);
  }

  failure(message?, title?) {
    const toast = this.preToast('failure', message, title);
    this.activateToast(toast);
  }

  warning(message?, title?) {
    const toast = this.preToast('warning', message, title);
    this.activateToast(toast);
  }

  info(message?, title?) {
    const toast = this.preToast('info', message, title);
    this.activateToast(toast);
  }
}
