<div class="toast fade" role="alert" aria-live="assertive" aria-atomic="true" [ngClass]="showToast(toast)">
  <div class="toast-icon">
    <div class="icon-size-md toast-icon" [ngClass]="getIconClass(toast)"></div>
  </div>
  <div class="toast-content">
    <div class="toast-header" *ngIf="toast.title">
      {{ toast.title }}
    </div>
    <div class="toast-body">
      {{ toast.message }}
    </div>
  </div>
  <div class="toast-x">
    <button type="button" class="close" aria-label="Close">
      <span aria-hidden="true" (click)="remove()">&times;</span>
    </button>
  </div>
</div>