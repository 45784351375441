import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations' ;
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './component/header/header.component';
import { LoginComponent } from './component/login/login.component';
import { SideNavComponent } from './component/side-nav/side-nav.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { SearchComponent } from './component/search/search.component';
import { FilterRowComponent } from './component/filter-row/filter-row.component';
import { SearchFilterPipe } from './pipe/search-filter.pipe';
import { initializer } from './utils/keycloak-init';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { AppService } from './service/app.service';

import { ToastComponent } from './component/toast/toast.component';
import { ToastContainerComponent } from './component/toast/toast-container/toast-container.component';
import { TranslateService } from './service/translate.service';
import { SharedModule } from './modules/shared/shared.module';
import { HomeComponent } from './component/home/home.component';
import { UserService } from './service/user.service';
import { ComboBoxPipe } from './pipe/combo-box.pipe';
import { FeaturePocComponent } from './component/feature-poc/feature-poc.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    SideNavComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    SearchComponent,
    FilterRowComponent,
    SearchFilterPipe,
    ToastComponent,
    ToastContainerComponent,
    HomeComponent,
    ComboBoxPipe,
    FeaturePocComponent,
  ],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    /* OwlDateTimeModule,
    OwlNativeDateTimeModule, */
    ReactiveFormsModule,
    NgbModule,
    SharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, AppService, UserService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translateService: TranslateService) => {
        return (): Promise<any> => {
          return translateService.loadLocale();
        };
      },
      multi: true,
      deps: [TranslateService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
