import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AppService } from '../service/app.service';
import { UserService } from '../service/user.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(public router: Router, public userApi: UserService, private api: AppService) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if(route.data.hasOwnProperty('flag')) {
      if (!this.api.isFeatureEnabled(route.data.flag)){
        return false;
      }
    }
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    let { isAdmin: admin, hasElevateAccess, hasFinancialsAccess, ingenicoUser } = this.userApi.userRights;
    admin = admin ? 'admin' : false;
    hasElevateAccess = hasElevateAccess ? 'hasElevateAccess' : false;
    hasFinancialsAccess = hasFinancialsAccess ? 'hasFinancialsAccess' : false;
    // let isIngenicoAdminHasElevateAccess = ingenicoUser && admin && hasElevateAccess ? 'isIngenicoAdminHasElevateAccess' : false;

    switch (route.routeConfig.path) {
      case 'administration':
        if (admin !== expectedRole) {
          this.router.navigate(['home']);
          return false;
        }
        break;
      case 'analytics':
        if (hasElevateAccess !== expectedRole) {
          this.router.navigate(['home']);
          return false;
        }
        break;
      case 'analytics-poc':
        if (hasElevateAccess !== expectedRole) {
          this.router.navigate(['home']);
          return false;
        }
        break;        
      default:
        break;
    }
      return true;

  }
}
