<div class="page-header">
  <div class="container">
    <div class="row">
      <div class="col col-page-title">
        <img src="../../../assets/logo_inverted.png" alt="Ingenico Insight" class="logo-dimension">
        <span class="insight-font">&nbsp;&nbsp;Insight</span>
      </div>
      <div class="col col-search">
        <app-search></app-search>
      </div>
      <div class="col col-user" (appClickAway)="showUserDetails = false">
        <!-- <button type="button" class="btn btn-secondary btn-only-icon btn-lg is-on-dark-background">
          <div class="btn-icon icon-bell-white"></div>
        </button> -->
        <button type="button" class="btn btn-secondary btn-only-icon btn-lg is-on-dark-background" aria-label="User details" (click)="toggleUserDetails()">
          <div class="btn-icon icon-user-2-white"></div>
        </button>
        <div class="user-details rounded shadow-background-dropdown border" *ngIf="showUserDetails">
          <div class="user-row">
            <div class="row">
              <div class="col-4 flex-center">
                  <div class="icon-user-2 icon-size-lg"></div>
              </div>
              <div class="col-8">
                <h6 class="h6">{{ firstName }} {{ lastName }}</h6>
              </div>
            </div>
          </div>
          <div class="user-footer">
            <button class="btn btn-secondary btn-sm" (click)="openChangePasswordPopup(changePasswordModal)" appI18n="@@changePwdLabel">Change Password</button>
            <button class="btn btn-primary btn-sm" (click)="logout()" appI18n="@@logoutLabel">Logout</button>
          </div>
        </div>
        <!-- <button (click)="logout()" class="btn btn-secondary ml-2"><ng-container *ngIf="userDetails">{{ userDetails.username }}</ng-container> Logout</button> -->
      </div>
    </div>
  </div>

  <ng-template #changePasswordModal let-modal>
    <div class="modal-header">
      <h4 id="modal-basic-title" class="modal-title" id="modal-basic-title" appI18n="@@changePwdLabel">Change Password</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal(modal)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="changePwdForm"> 
        <div class="form-group">
          <label>Old Password</label>
          <input type="password" class="form-control" id="oldPwd" formControlName="oldPwd" 
            required [ngClass]="{ 'is-invalid': oldPwd.invalid && (oldPwd.dirty || oldPwd.touched) }">
          <div *ngIf="oldPwd.invalid && (oldPwd.dirty || oldPwd.touched)" class="invalid-feedback">
            <div *ngIf="oldPwd.errors.required">
              Old Password is required.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>New Password</label>
          <input id="newPwd" type="password" class="form-control" formControlName="newPwd" minlength="8" required [ngClass]="{ 'is-invalid': newPwd.invalid && (newPwd.dirty || newPwd.touched) }">
          <div *ngIf="newPwd.invalid && (newPwd.dirty || newPwd.touched)" class="invalid-feedback">
            <div *ngIf="newPwd.errors.required">
              Password is required.
            </div>
            <div *ngIf="newPwd.errors.minlength">
              Password must be at least 8 characters long.
            </div>
            <div *ngIf="newPwd.errors.minDigit">
              Password must have atleast 1 digit.
            </div>
            <div *ngIf="newPwd.errors.minLowerCase">
              Password must have atleast 1 Lower case alphabet.
            </div>
            <div *ngIf="newPwd.errors.minUpperCase">
              Password must have atleast 1 Upper case alphabet.
            </div>
            <div *ngIf="newPwd.errors.specialChar">
              Password must have atleast 1 Special character. Allowed characters ? ! # % $ @
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <label>Confirm Password</label>
          <input id="confirmPwd" type="password" class="form-control" formControlName="confirmPwd" 
            required [ngClass]="{ 'is-invalid': confirmPwd.invalid && (confirmPwd.dirty || confirmPwd.touched) }" [attr.disabled]="isConfirmDisabled()">
          <div *ngIf="confirmPwd.invalid && (confirmPwd.dirty || confirmPwd.touched)" class="invalid-feedback">
            <div *ngIf="confirmPwd.errors.required">
              Confirm Password is required.
            </div>
            <div *ngIf="confirmPwd.errors.mustMatch">
              Confirm Password must match Password
            </div>
          </div>
        </div>
        
      </form>
    </div>
    <div class="modal-footer">
      <div class="container m-0">
        <div class="row">
          <div class="col-9">
            <button type="button" class="btn btn-secondary" (click)="closeModal(modal)" appI18n="@@cancelLabel">Cancel</button>
          </div>
          <!-- <div class="col-9">
            <div class="alert alert-success" role="alert" *ngIf="status.valid">{{ status.message || 'Password changed successfully' }}</div>
            <div class="alert alert-danger" role="alert" *ngIf="status.invalid">{{ status.message || '' }}</div>
          </div> -->
          <div class="col-3 text-right">
            <button type="button" class="btn btn-primary" (click)="save(modal)" [disabled]="isFormInvalid()">Update</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
