<div class="side-nav-container" [ngClass]="{'nav-collapse': isCollapsed}">
  <ul class="nav flex-column" (mouseenter)="mouseaction(false)" (mouseleave)="mouseaction(true)">
    <li class="nav-item">
      <a class="nav-link" routerLink="/payments" routerLinkActive="active" (click)="menuClick()">
        <div class="i icon-coin-5 icon-size-sm mr-1" appI18n="attribute|title@@paymentsLabel"></div>
        <span appI18n="@@paymentsLabel" class="label" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Payments</span>
      </a>
    </li>
    <!-- Old Performance HIDDEN -->
    <li class="nav-item" *ngIf="false">
      <a class="nav-link" routerLink="/analytics" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="menuClick('analytics')" *ngIf="hasElevateAccess">
        <div class="i icon-chart-6 icon-size-sm mr-1" appI18n="attribute|title@@performanceLabel"></div>
        <span appI18n="@@performanceLabel" class="label" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Performance</span>
      </a>
      <ul class="nav flex-column sub-nav" *ngIf="showAnalyticsSubMenu">
        <li class="nav-item">
          <a class="nav-link" routerLink="/analytics/authorization-rate" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="menuClick('analytics', 'authorization')" #authorization="routerLinkActive">
            <div class="i icon-size-xs mr-1" [ngClass]="getActiveClass(authorization)"></div>
            <span appI18n="@@authorizationRateLabel" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Authorization Rate</span>
          </a>
          <ul class="nav flex-column sub-nav-l2" *ngIf="showAuthorizationSubMenu">
            <li class="nav-item">
              <a class="nav-link" routerLink="/analytics/authorization-bin-overview" routerLinkActive="active" (click)="menuClick('analytics', 'authorization')" #bin="routerLinkActive">
                <div class="i icon-size-xs mr-1" [ngClass]="getActiveClass(bin)"></div>
                <span appI18n="@@binOverviewLabel" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Bin Overview</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/analytics/authorization-rejections" routerLinkActive="active" (click)="menuClick('analytics', 'authorization')" #rejections="routerLinkActive">
                <div class="i icon-size-xs mr-1" [ngClass]="getActiveClass(rejections)"></div>
                <span appI18n="@@rejectionsLabel" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Rejections</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/analytics/dispute" routerLinkActive="active" (click)="menuClick('analytics')" #dispute="routerLinkActive">
            <div class="i icon-size-xs mr-1" [ngClass]="getActiveClass(dispute)"></div>
            <span appI18n="@@disputeLabel" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Dispute</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/analytics/chargeback" routerLinkActive="active" (click)="menuClick('analytics')" #chargeback="routerLinkActive">
            <div class="i icon-size-xs mr-1" [ngClass]="getActiveClass(chargeback)"></div>
            <span appI18n="@@chargebackLabel" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Chargeback</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/analytics/refund" routerLinkActive="active" (click)="menuClick('analytics')" #refund="routerLinkActive">
            <div class="i icon-size-xs mr-1" [ngClass]="getActiveClass(refund)"></div>
            <span appI18n="@@refundLabel" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Refund</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="hasElevateAccess">
      <!-- <a class="nav-link" routerLink="/home" routerLinkActive="active" (click)="menuClick()">
        <div class="i icon-home-7 icon-size-sm mr-1"></div>
        <span appI18n="@@homeLabel">Home</span>
      </a> -->
      <a class="nav-link" [routerLink]="['/analytics-poc', 'performance']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="menuClick('analytics-poc')" *ngIf="hasElevateAccess">
        <div class="i icon-chart-6 icon-size-sm mr-1"></div>
        <span [@enterLeave]="isCollapsed ? 'hide' : 'show'">Performance</span>
      </a>
      <ul class="nav flex-column sub-nav" *ngIf="showAnalyticsPOCSubMenu">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/analytics-poc','authorization-rate']" routerLinkActive="active" (click)="menuClick('analytics-poc', 'authorization-poc')">
            <span [@enterLeave]="isCollapsed ? 'hide' : 'show'">Authorization</span>
          </a>
          <!-- <ul class="nav flex-column sub-nav-l2" *ngIf="showAuthorizationPOCSubMenu"> -->
          <ul class="nav flex-column sub-nav-l2">
            <li class="nav-item">
              <a class="nav-link" routerLink="/analytics-poc/authorization-rejections-poc" routerLinkActive="active" (click)="menuClick('analytics-poc', 'authorization-poc')" #bin="routerLinkActive">
                <span [@enterLeave]="isCollapsed ? 'hide' : 'show'">Rejections</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/analytics-poc/bin-poc" routerLinkActive="active" (click)="menuClick('analytics-poc', 'authorization-poc')" #bin="routerLinkActive">
                <span [@enterLeave]="isCollapsed ? 'hide' : 'show'">BIN</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/analytics-poc','refund']" routerLinkActive="active" (click)="menuClick('analytics-poc')">
            <span [@enterLeave]="isCollapsed ? 'hide' : 'show'">Refund</span>
          </a>
        </li>        
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/analytics-poc','chargeback']" routerLinkActive="active" (click)="menuClick('analytics-poc')">
            <span [@enterLeave]="isCollapsed ? 'hide' : 'show'">Chargeback</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/analytics-poc','dispute']" routerLinkActive="active" (click)="menuClick('analytics-poc')">
            <span [@enterLeave]="isCollapsed ? 'hide' : 'show'">Dispute</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/analytics-poc','payment-product']" routerLinkActive="active" (click)="menuClick('analytics-poc')">
            <span [@enterLeave]="isCollapsed ? 'hide' : 'show'">Payment Product</span>
          </a>
        </li>        
      </ul>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/finance" routerLinkActive="active" (click)="menuClick()" *ngIf="hasFinancialsAccess">
        <div class="i icon-financials icon-size-sm mr-1"></div>
        <span appI18n="@@financialReportLabel" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Financials</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/administration" routerLinkActive="active" (click)="menuClick()" *ngIf="isAdmin">
        <div class="i icon-volume-control-9 icon-size-sm mr-1" appI18n="attribute|title@@administrationLabel"></div>
        <span appI18n="@@administrationLabel" class="label" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Administration</span>
      </a>
    </li>

    <li>
      <a class="nav-link" routerLink="/feature-poc" routerLinkActive="active" (click)="menuClick()" *ngIf="featureEnabled('FEATURE_FLAG_1')">
        <div class="i icon-volume-control-9 icon-size-sm mr-1" appI18n="attribute|title@@administrationLabel"></div>
        <span class="label" [@enterLeave]="isCollapsed ? 'hide' : 'show'">Feature POC</span>
      </a>
    </li>
  </ul>
</div>
