import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RequestCacheService } from '../service/request-cache.service';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private cache: RequestCacheService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const cacheName = request.params.get('cache');
        if (!request.headers.has('Content-type')) {
            request = request.clone({headers: request.headers.set('Content-type', 'application/json')});
        }
        if (!cacheName) {
            
            request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
            return this.sendRequest(request, next);
        } else {
            const url = `${request.url}##${cacheName}`;
            const cachedResponse = this.cache.get(url);
            return cachedResponse ? of(cachedResponse) : this.sendRequest(request, next, true);
        }
    }

    sendRequest(req: HttpRequest<any>, next: HttpHandler, cached = false): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (cached) {
                        const cacheName = req.params.get('cache');
                        const url = `${req.url}##${cacheName}`;
                        this.cache.set(url, event);
                    }
                }
                return event;
            }));
    }
}
