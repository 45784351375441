import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { HomeComponent } from './component/home/home.component';
import { RoleGuard } from './auth/role.guard';
import { FeaturePocComponent } from './component/feature-poc/feature-poc.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'payments', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'analytics', loadChildren: './modules/analytics/analytics.module#AnalyticsModule',
        canActivate: [RoleGuard],
        data: {
          expectedRole: 'hasElevateAccess'
        }
      },
      { path: 'payments', loadChildren: './modules/payments/payments.module#PaymentsModule' },
      { path: 'administration', loadChildren: './modules/administration/administration.module#AdministrationModule',
        canActivate: [RoleGuard],
          data: {
          expectedRole: 'admin'
        }
      },
      { path: 'analytics-poc', loadChildren: './modules/analytics-poc/analytics-poc.module#AnalyticsPocModule',
        canActivate: [RoleGuard],
        data: {
          expectedRole: 'hasElevateAccess'
        } 
      },
      { path: 'finance', loadChildren: './modules/financial-report/financial-report.module#FinancialReportModule',
        canActivate: [RoleGuard],
        data: {
          expectedRole: 'hasFinancialsAccess'
        }
      },
      { path: 'feature-poc', component: FeaturePocComponent,
        canActivate: [RoleGuard],
        data: {
          flag: 'FEATURE_FLAG_1'
        }
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: true})
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
