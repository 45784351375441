import moment from 'moment';
import { chain } from 'lodash';

/**
 * Array move element from one position to another
 * @param from number
 * @param to number
 */
export function move(from: number, to: number): Array<any> {
    if (typeof to !== 'number' || typeof from !== 'number') {
        return this;
    }
    if ((to < 0 && to > this.length) || (from < 0 && from > this.length)) {
        return this;
    }
    this.splice(to, 0, this.splice(from, 1)[0]);
    return this;
}

/**
 * Generic date and time formatter
 * @param date Date
 * @param inputFormat string
 */
export function formatDateAndTime(date: any, inputFormat: string = 'DD MMM YYYY HH:mm:ss', ) {
    let dtString;
    if (date === '') { return null; }
    if (typeof date === 'string') {
        dtString = moment(date, 'YYYYMMDDHHmmss').format(inputFormat);
    } else if (date instanceof Date) {
        dtString = moment(date).format(inputFormat);
    } else {
        return null;
    }
    return dtString;
}

/**
 * Generic date formatter
 * @param date Date
 * @param inputFormat string
 */
export function formatDate(date: any, inputFormat: string = 'DD MMM YYYY') {
    let dtString;
    if (date === '') { return null; }
    if (typeof date === 'string') {
        dtString = moment(date, 'YYYYMMDDHHmmss').format(inputFormat);
    } else if (date instanceof Date) {
        dtString = moment(date).format(inputFormat);
    } else {
        return null;
    }
    return dtString;
}

/**
 * Generic time formatter
 * @param date Date
 * @param inputFormat string
 */
export function formatTime(date: any, inputFormat: string = 'HH:mm:ss') {
    let dtString;
    if (date === '') { return null; }
    if (typeof date === 'string') {
        dtString = moment(date, 'HHmmss').format(inputFormat);
    } else if (date instanceof Date) {
        dtString = moment(date).format(inputFormat);
    } else {
        return null;
    }
    return dtString;
}


export function trimExtraSpaces(input: string) {
    const regex = /\s+/g;
    return input.trim().replace(regex, ' ');
}

/**
 * returns number is million comma separator in string format
 * @param x is number
 */
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
} 

/**
 * returns string with million comma separator along with fixed number of decimals.
 * @param a is number
 */
export function formatAmount(a: number, currency: string, millionSeparator = true) {
    // special currency which has different number of decimal
    // special is mapping object having currency as key and number of decimals as value
    // currently default all currencies to two deciamal
    /* const special = {
        "BHD": 3,
        "CLF": 4,
        "IQD": 3,
        "JOD": 3,
        "KWD": 3,
        "LYD": 3,
        "OMR": 3,
        "TND": 3,
        "UYW": 4
    }; */
    const special = {};
    const noofdecimal = special[currency] || 2;
    let value = a.toFixed(noofdecimal);
    if (millionSeparator) {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return value;
}

export function getCurrencySign(currency) {
    // Add new currencies adhoc
    const currencySignMap: Array<any> = [
        { name: 'EUR', sign: '€'},
        { name: 'USD', sign: '$'},
        { name: 'GBP', sign: '£'},
    ];
    return currencySignMap.find(item => item.name === currency).sign || currency;
}

export function featureFlag(config: any, currentUser: any) {
    const userEmail = currentUser.email;
    const u = config.FF_USERS ? config.FF_USERS.split(',') : [];
    const flags = chain(config)
        .keys()
        .filter((v) => v.includes('FEATURE_FLAG'))
        .reduce((filteredFlags, item) => {
            filteredFlags[item] = config[item];
            return filteredFlags;
        }, {})
        .value()
    return function (flag) {
        if (flags[flag] === 'ALL') { return true; } // flag value 'ALL' means feature is tested working and should be exposed to all users
        if (!u || u.length === 0) { return false; } // check if there are any users with access to features
        if (!u.includes(userEmail)) { return false; } // check if current user is in the list of users who has access to features
        return flags[flag] === 'YES' ? true : false; // flag value is either 'Y' or 'N', current user is in the list of users who has access to features
    }
}

// splitArrayIntoChunksOfLen([1,2,3,4,5,6], 2) will return [[1,2],[3,4],[5,6]]
export function splitArrayIntoChunksOfLen (arr, len) {
    var chunks = [], i = 0, n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}