import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  language: string;
  data: any = {};
  dataObservable = new BehaviorSubject<any>(this.data);

  constructor(private http: HttpClient) { }

  eventChange() {
    this.dataObservable.next(this.data);
  }

  /**
   * Fetch locale file based on selection
   * @param lang Language code. Default '' returns English locale file
   */
  loadLocale(lang = 'en') {
    this.language = lang;
    return new Promise<any>((resolve, reject) => {
      const path = `assets/locale/${this.language}.json`;
      this.http.get(path).subscribe(translation => {
        this.data = Object.assign({}, translation || {});
        this.eventChange();
        resolve(this.data);
      },
      error => {
        console.error('Translation error');
        this.data = {};
        resolve(this.data);
      });
    });
  }

  translateKey(key = 'defaultErrorMessage') {
      const message = (this.data.hasOwnProperty(key)) ? this.data[key] : `Cqnc:${key}`;
      return message;
  }

  translateError(error) {
      const key = error.error.message || 'defaultErrorMessage';
      const message = (this.data.hasOwnProperty(key)) ? this.data[key] : `Cqnc:${key}`;
      const args = (error.error.args && error.error.args.length) ? error.error.args : false;
      return args ? this.replacePlaceHolders(message, args) : message;
    }

  replacePlaceHolders = (message, args) => {
    let newMessage = message;
    args.forEach((element, index) => {
        const regex = new RegExp(`\\$${index + 1}`, 'gi') ;
        newMessage = newMessage.replace(regex, element);
    });
    return newMessage;
}

}
