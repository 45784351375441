import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'app-home-layout',
  template: `
    <div class="page-layout">
      <app-toast-container></app-toast-container>
      <app-header></app-header>
      <div class="coming-soon">
        <div class="text">Coming Soon</div>
      </div>

      <div class="page-side-nav">
        <div class="container">
          <div class="row">
            <div class="col col-side-nav" 
              [ngClass]="{'col-collapse': isCollapsed}"
              >
              <div class="side-menu-wrapper" >
                <app-side-nav
                  [fixedSideMenu]="fixedSideMenu"
                  (collapseNav) = "collapseNav($event)"
                  (mouseoveraction) = "mouseoveraction($event)"
                ></app-side-nav>
              </div>
              <div class="side-menu-footer" [ngClass]="{'right': !isCollapsed}" (click)="fixSideMenuOpen()">
                <div class="icon-arrow-25 icon-size-sm d-inline-block" [ngClass]="{'nav-expanded': !isCollapsed}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-main">
        <div class="container">
          <div class="row">
            <div class="col col-main" [ngClass]="{'col-collapse': isCollapsed}">
              <div class="page-container-off">
                <!--<div class="page-container-header">
                  <h1>Optional Header</h1>
                </div>-->

                <router-outlet></router-outlet>
              </div>

              <!--<div class="page-footer">
                Footer
              </div>-->
            </div>

            <!--<div class="col col-right">
              Optional Right Column
            </div>-->
          </div>
        </div>
      </div>
    </div>
  `,
  styles: ['.app-container {max-width: 1440px; margin-top: 64px}',
    '.side-menu-wrapper {flex-grow: 1}'],
  animations: [
    trigger('inOut', [
      transition('* => col-collapse', [
        animate('0.5s', keyframes([
          style({'overflow-x': 'hidden', offset: 0}),
          style({'overflow-x': 'hidden', offset: 0.9}),
          style({'overflow-x': 'hidden', offset: 1}),
        ]))
      ])
    ])
  ]
})
export class HomeLayoutComponent implements OnInit {
  isCollapsed = false;
  fixedSideMenu = true;

  constructor(private appService: AppService) { }

  ngOnInit() {
  }

  collapseNav(event) {
    this.isCollapsed = event
  }

  mouseoveraction(event) {
    if (this.fixedSideMenu) { return; }
    this.isCollapsed = event;
    if (this.isCollapsed) {
      this.appService.resizeSubject.next(true);
    }
  }

  mouseenteraction() {
    if (this.fixedSideMenu) { return; }
    this.isCollapsed = false;
  }

  mouseleaveaction() {
    if (this.fixedSideMenu) { return; }
    this.isCollapsed = true;
    this.appService.resizeSubject.next(true);
  }

  fixSideMenuOpen() {
    this.fixedSideMenu = !!this.isCollapsed;
    if (this.fixedSideMenu) {
      this.isCollapsed = false;
    } else {
      this.isCollapsed = true;
    }
    this.appService.resizeSubject.next(true);
  }
}
