export class AppConstants {
    /*
        filter types:  TEXT, NUMERIC, DATE || TIME, LIST
        relations: eq, lt, gt, lte, gte
        enum types: string, number
    */
    // TODO: enable commeted out options when it is available in the backend
    public static get filterMap(): Array<any> {
        return [
            {name: 'bin', description: 'binLabel', type: 'TEXT', options: ['eq']},
            {name: 'consumerEmail', description: 'emailAddressLabel', type: 'TEXT', options: ['contains'], quickSearch: true, tip: 'emailSearchTip', quickSearchOrder: 4},
            {name: 'customerId', description: 'consumerIdLabel', type: 'TEXT', options: ['startsWith']},
            {name: 'consumerIp', description: 'consumerIpLabel', type: 'TEXT', options: ['eq']},
            {name: 'country', description: 'countryLabel', type: 'LIST', options: ['eq']},
            {name: 'currencyCode', description: 'paymentCurrencyLabel', type: 'LIST', options: ['eq']},
            {name: 'merchantId', description: 'merchantIdLabel', type: 'LIST', options: ['eq']},
            {name: 'merchantOrderId', description: 'merchantOrderLabel', type: 'TEXT', options: ['startsWith'], quickSearch: true, tip: 'numericSearchTip', quickSearchOrder: 2},
            {name: 'orderId', description: 'orderIdLabel', type: 'TEXT', options: ['startsWith'], quickSearch: true, tip: 'numericSearchTip', quickSearchOrder: 1},
            {name: 'createdDate', description: 'paymentDateLabel', type: 'DATE', options: []},
            {name: 'paymentMethod', description: 'paymentMethodLabel', type: 'LIST', options: ['eq']},
            {name: 'paymentProduct', description: 'paymentProductLabel', type: 'LIST', options: ['eq']},
            {name: 'paymentRef', description: 'paymentRefLabel', type: 'TEXT', options: ['startsWith'], quickSearch: true, tip: 'alphaNumericSearchTip', quickSearchOrder: 3},
            {name: 'transactionStatus', description: 'paymentStatusLabel', type: 'LIST', options: ['eq']},
            {name: 'statusCode', description: 'paymentStatusIdLabel', type: 'LIST', options: ['eq']},
            {name: 'profileToken', description: 'tokenLabel', type: 'TEXT', options: ['eq']},
            {name: 'additionalReference', description: 'merchantReferenceLabel', type: 'TEXT', options: ['eq'], quickSearch: true, tip: 'alphaNumericSearchTip', quickSearchOrder: 5},
            {name: 'errorName', description: 'rejectionReasonLabel', type: 'TEXT', options: ['eq']},
            // {name: 'issuingBankName', description: 'issuingBankNameLabel', type: 'TEXT', options: ['eq']},
            {name: 'iban', description: 'ibanLabel', type: 'TEXT', options: ['eq']},
            {name: 'currentStatusDate', description: 'currentStatusDateLabel', type: 'DATE', options: []},
            {name: 'chargebackReasonDescriptionInternal', description: 'chargebackReasonDescriptionLabel', type: 'TEXT', options: ['eq']},
            
            // {name: '3ds', description: '3dSecure', type: 'DROPDOWN', values: ['yesLabel', 'noLabel']},
            // Not business requirement but available in backend
            /*
            {name: 'connectPaymentStatus', description: 'connect payment status', type: ''},
            {name: 'creditCardNumber', description: 'Credit card number', type: ''},
            */
        ];
    }

    public static get consumerBasedFilterMap(): Array<any> {
        return [
            {name: 'consumerFirstName', description: 'consumerFirstNameLabel', type: 'TEXT', options: ['eq']},
            {name: 'consumerSurname', description: 'consumerSurnameLabel', type: 'TEXT', options: ['eq']},
            {name: 'consumerZipCode', description: 'consumerZipCodeLabel', type: 'TEXT', options: ['eq']},
            {name: 'consumerCityName', description: 'consumerCityNameLabel', type: 'TEXT', options: ['eq']},
        ];
    }

    /**
     * Contains array of columns which should not be removed from table
     * IMP: value of 'prop' MUST match to prop (of the column) in columnMap()
     */
    public static get mandatoryColumnMap(): Array<any> {
        return [
            { name: 'Order Id', prop: 'orderId' }
        ];
    }

    public static get columnMap(): Array<any> {
        return [
            /* { name: 'Bulk Selection', checkboxable: true, headerCheckboxable: true, width: 30, sortable: false, canAutoResize: false,
            draggable: false, resizeable: false, frozenLeft: true, _isColumnVisible: true, _isViewable: true }, */
            { name: 'Merchant Id', prop: 'merchant.accountId', sortable: true, frozenLeft: true, width: 200, draggable: true,
            resizeable: true, _isColumnVisible: true, _isViewable: true },
            { name: 'Order Id', prop: 'orderId', sortable: true, frozenLeft: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: true, _isViewable: true},
            { name: 'Merchant Order Id', prop: 'merchantOrderId', sortable: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: true, _isViewable: true },
            { name: 'Status', prop: 'currentStatus.transactionStatus', sortable: true, width: 300, draggable: true, resizeable: true,
            _isColumnVisible: true, _isViewable: true},
            { name: 'Status code', prop: 'currentStatus.statusId', sortable: true, width: 100, draggable: true, resizeable: true,
            _isColumnVisible: true, _isViewable: true},
            { name: 'Payment Currency', prop: 'paidAmount.currencyCode',  sortable: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: true, _isViewable: true},
            { name: 'Merchant Currency', prop: 'requestData.requestCurrencyCode',  sortable: false, width: 100, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: false},
            { name: 'Amount', prop: 'requestData.requestAmount', sortable: true, width: 200, draggable: true, resizeable: true,
            _isColumnVisible: true, _isViewable: true },
            { name: 'Country', prop: 'requestData.requestCountryName', sortable: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: true, _isViewable: true },
            { name: 'Country code', prop: 'requestData.requestCountryCode', sortable: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: false },
            { name: 'Payment Product', prop: 'paymentProduct.name', sortable: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: true, _isViewable: true },
            { name: 'Created Date / Time', prop: 'createdDate', sortable: true, width: 200, draggable: true, resizeable: true, 
            _isColumnVisible: true, _isViewable: true },
            { name: 'Customer Id', prop: 'consumer.customerId', sortable: true, width: 150, draggable: true, resizeable: true, _isColumnVisible: false,
            _isViewable: true },
            { name: 'Payment Reference', prop: 'paymentReference', sortable: false, width: 150, draggable: true, resizeable: true, _isColumnVisible: false,
            _isViewable: true},
            { name: 'Effort Id', prop: 'effortId', sortable: false, width: 70, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Attempt Id', prop: 'attemptId', sortable: false, width: 70, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Bin', prop: 'paymentInstrument.iin', sortable: false, width: 150, draggable: true, resizeable: true, _isColumnVisible: false
            , _isViewable: true},
            { name: 'Profile token', prop: 'paymentInstrument.profileToken', sortable: false, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Expiry Date', prop: 'paymentInstrument.expiryDate', sortable: false, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Email', prop: 'consumer.email', sortable: true, width: 300, draggable: true, resizeable: true, _isColumnVisible: false,
            _isViewable: true },
            { name: 'IP address', prop: 'consumer.ipAddress', sortable: true, width: 150, draggable: true, resizeable: true, _isColumnVisible: false
            , _isViewable: true },
            { name: '3D Secure flag', prop: 'externalServices.authenticationInd', sortable: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: '3D Secure result', prop: 'authenticationTry.validationResult', sortable: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Chargeback type', prop: 'chargebacks.reasonFraudFlag', sortable: false, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Recurring Indicator', prop: 'requestData.recurringInd', sortable: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Payment Method', prop: 'paymentMethod.name', sortable: true, width: 150, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Status color', prop: 'currentStatus.paymentStatus', sortable: false, draggable: false, resizeable: true,
            _isColumnVisible: false, _isViewable: false },
            { name: 'Status color', prop: 'merchant.accountName', sortable: false, draggable: false, resizeable: true,
            _isColumnVisible: false, _isViewable: false },
            { name: 'Merchant Reference', prop: 'additionalReference', sortable: false, width: 200, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Rejection Reason', prop: 'error.errorName', sortable: false, width: 200, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            // { name: 'Issuing Bank', prop: 'paymentInstrument.iinIssuingBankName', sortable: false, width: 200, draggable: true, resizeable: true,
            // _isColumnVisible: false, _isViewable: true },
            { name: 'IBAN', prop: 'paymentInstrument.iban', sortable: false, width: 200, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
            { name: 'Current Status Date', prop: 'currentStatus.statusDate', sortable: false, width: 200, draggable: true, resizeable: true,
            _isColumnVisible: false, _isViewable: true },
        ];
    }

    public static get consumerBasedColumnMap(): Array<any> {
        return [
            { name: 'First Name', prop: 'consumer.firstname', sortable: true, frozenLeft: true, width: 200, draggable: true,
            resizeable: true, _isColumnVisible: false, _isViewable: true, consumerBased: true },
            { name: 'Last Name', prop: 'consumer.surname', sortable: true, frozenLeft: true, width: 200, draggable: true,
            resizeable: true, _isColumnVisible: false, _isViewable: true, consumerBased: true },
            { name: 'Postal code', prop: 'consumer.zipCode', sortable: true, width: 200, draggable: true,
            resizeable: true, _isColumnVisible: false, _isViewable: true, consumerBased: true },
            { name: 'City', prop: 'consumer.cityName', sortable: true, width: 200, draggable: true,
            resizeable: true, _isColumnVisible: false, _isViewable: true, consumerBased: true },
        ];
    }

    public static get paymentDetailsColumnMap(): Array<any> {
        return [
            { displayName: 'Moea', prop: 'moea', group: '', order: 1 },
            { displayName: 'Status', prop: 'currentStatus.transactionStatus', group: '', order: 1 },
            { displayName: 'Status code', prop: 'currentStatus.statusId', group: '', order: 1 },
            { displayName: 'Status date', prop: 'currentStatus.statusDate', group: '', order: 1 },
            { displayName: 'Status type', prop: 'currentStatus.paymentStatus', group: '', order: 1 },
            { displayName: 'Status color', prop: 'currentStatus.paymentStatus', group: '', order: 1 },
            { displayName: 'Currency', prop: 'requestData.requestCurrencyCode', group: '', order: 1 },
            { displayName: 'Merchant Id', prop: 'merchant.accountId', group: 'IDENTIFICATION', order: 1 },
            { displayName: 'Merchant order ID', prop: 'merchantOrderId', group: 'IDENTIFICATION', order: 1 },
            { displayName: 'Effort Id', prop: 'effortId', group: 'IDENTIFICATION', order: 1 },
            { displayName: 'Payment reference', prop: 'paymentReference', group: 'IDENTIFICATION', order: 1 },
            { displayName: 'Order Id', prop: 'orderId', group: 'IDENTIFICATION', order: 1 },
            { displayName: 'Attempt Id', prop: 'attemptId', group: 'IDENTIFICATION', order: 1 },
            { displayName: 'Invoice Number', prop: '', group: 'IDENTIFICATION', order: 1 },
            { displayName: 'Customer Id', prop: 'consumer.customerId', group: 'IDENTIFICATION', order: 1 },
            { displayName: 'Additional Reference', prop: 'additionalReference', group: 'IDENTIFICATION', order: 1 },
            { displayName: 'Rejection Reason', prop: 'error.errorName', group: 'IDENTIFICATION', order: 1 },
            // payment method
            { displayName: 'Payment Method', prop: 'paymentMethod.name', group: 'PAYMENT_METHOD', order: 1 },
            { displayName: 'Payment Product', prop: 'paymentProduct.name', group: 'PAYMENT_METHOD', order: 1 },
            { displayName: 'Card Number', prop: 'paymentInstrument.iin', group: 'PAYMENT_METHOD', order: 1 },
            { displayName: 'Expiry Date', prop: 'paymentInstrument.expiryDate', group: 'PAYMENT_METHOD', order: 1 },
            { displayName: 'Card Holder', prop: '', group: 'PAYMENT_METHOD', order: 1 },
            { displayName: 'Profile Token', prop: 'paymentInstrument.profileToken', group: 'PAYMENT_METHOD', order: 1 },
            { displayName: 'ARN', prop: '', group: 'PAYMENT_METHOD', order: 1 },
            // { displayName: 'Issuing Bank', prop: 'paymentInstrument.iinIssuingBankName', group: 'PAYMENT_METHOD', order: 1 },
            { displayName: 'IBAN', prop: 'paymentInstrument.iban', group: 'PAYMENT_METHOD', order: 1 },
            // Payment Info
            { displayName: 'Amount Delivered', prop: '', group: 'PAYMENT_INFO', order: 1 },
            { displayName: 'Amount Local', prop: '', group: 'PAYMENT_INFO', order: 1 },
            { displayName: 'Amount', prop: 'requestData.requestAmount', group: 'PAYMENT_INFO', order: 1 },
            { displayName: 'Payment Country', prop: 'requestData.requestCountryCode', group: 'PAYMENT_INFO', order: 1 },
            { displayName: 'Payment Country name', prop: 'requestData.requestCountryName', group: 'PAYMENT_INFO', order: 1 },
            { displayName: 'Currency', prop: 'requestData.requestCurrencyCode', group: 'PAYMENT_INFO', order: 1 },
            // Fraud
            { displayName: 'Chargeback Type', prop: 'chargebacks.reasonFraudFlag', group: 'FRAUD', order: 1 },
            { displayName: 'Fraud Status', prop: '', group: 'FRAUD', order: 1 },
            { displayName: 'Fraud Result', prop: 'fraudTry.resultName', group: 'FRAUD', order: 1 },
            { displayName: 'Fraud Code', prop: 'fraudTry.resultCode', group: 'FRAUD', order: 1 },
            { displayName: '3D Secure', prop: 'externalServices.authenticationInd', group: 'FRAUD', order: 1},
            { displayName: '3D Secure', prop: 'authenticationTry.validationResult', group: 'FRAUD', order: 1},
            // Payment history
            { displayName: 'Payment history', prop: 'paymentAttemptStatusHistory', group: 'HISTORY', order: 1, children: [
                { displayName: 'Payment Status date', prop: 'paymentAttemptStatusHistory.statusDate', group: 'HISTORY', order: 1 },
                { displayName: 'Payment Status id', prop: 'paymentAttemptStatusHistory.statusId', group: 'HISTORY', order: 1 },
                { displayName: 'Payment Status', prop: 'paymentAttemptStatusHistory.transactionStatus', group: 'HISTORY', order: 1 },
                { displayName: 'status color', prop: 'paymentAttemptStatusHistory.paymentStatus', group: 'HISTORY', order: 1 }
            ]},
            // Technical
            { displayName: 'IP Address Customer', prop: 'consumer.ipAddress', group: 'TECHNICAL', order: 1 },
            { displayName: 'Platform Id', prop: '', group: 'TECHNICAL', order: 1 },
            { displayName: 'Payment Source', prop: 'requestData.apiRequestSource', group: 'TECHNICAL', order: 1 },
            // Customer
            { displayName: 'Company Name', prop: 'consumer.companyName', group: 'CUSTOMER', order: 1 },
            { displayName: 'Company Data', prop: 'consumer.companyData', group: 'CUSTOMER', order: 1 },
            { displayName: 'Title', prop: 'consumer.title', group: 'CUSTOMER', order: 1 },
            { displayName: 'First name', prop: 'consumer.firstname', group: 'CUSTOMER', order: 1 },
            { displayName: 'Prefix', prop: 'consumer.prefixSurname', group: 'CUSTOMER', order: 1 },
            { displayName: 'Sruname', prop: 'consumer.surname', group: 'CUSTOMER', order: 1 },
            { displayName: 'Sex', prop: 'consumer.sex', group: 'CUSTOMER', order: 1 },
            { displayName: 'Birth date', prop: 'consumer.birthDate', group: 'CUSTOMER', order: 1 },
            { displayName: 'Street', prop: 'consumer.street', group: 'CUSTOMER', order: 1 },
            { displayName: 'House no', prop: 'consumer.houseNumber', group: 'CUSTOMER', order: 1 },
            { displayName: 'Additional address', prop: 'consumer.additionalAddressInfo', group: 'CUSTOMER', order: 1 },
            { displayName: 'Zip', prop: 'consumer.zipCode', group: 'CUSTOMER', order: 1 },
            { displayName: 'City', prop: 'consumer.cityName', group: 'CUSTOMER', order: 1 },
            { displayName: 'State', prop: 'consumer.stateName', group: 'CUSTOMER', order: 1 },
            { displayName: 'Phone number', prop: 'consumer.phoneNumber', group: 'CUSTOMER', order: 1 },
            { displayName: 'fax', prop: 'consumer.faxNumber', group: 'CUSTOMER', order: 1 },
            { displayName: 'Email', prop: 'consumer.email', group: 'CUSTOMER', order: 1 },
            { displayName: 'Email type ind', prop: 'consumer.emailTypeInd', group: 'CUSTOMER', order: 1 },
            // Billing
            // Shipping
            { displayName: 'Company Name', prop: 'consumer.companyName', group: 'SHIPPING', order: 1 },
            { displayName: 'Company Data', prop: 'consumer.companyData', group: 'SHIPPING', order: 1 },
            { displayName: 'Title', prop: 'consumer.shippingAddress.title', group: 'SHIPPING', order: 1 },
            { displayName: 'First name', prop: 'consumer.shippingAddress.firstname', group: 'SHIPPING', order: 1 },
            { displayName: 'Prefix', prop: 'consumer.shippingAddress.prefixSurname', group: 'SHIPPING', order: 1 },
            { displayName: 'Sruname', prop: 'consumer.shippingAddress.surname', group: 'SHIPPING', order: 1 },
            { displayName: 'Sex', prop: 'consumer.shippingAddress.sex', group: 'SHIPPING', order: 1 },
            { displayName: 'Birth date', prop: '', group: 'SHIPPING', order: 1 },
            { displayName: 'Street', prop: 'consumer.shippingAddress.street', group: 'SHIPPING', order: 1 },
            { displayName: 'House no', prop: 'consumer.shippingAddress.houseNumber', group: 'SHIPPING', order: 1 },
            { displayName: 'Additional address', prop: 'consumer.shippingAddress.additionalAddressInfo', group: 'SHIPPING', order: 1 },
            { displayName: 'Zip', prop: 'consumer.shippingAddress.zipCode', group: 'SHIPPING', order: 1 },
            { displayName: 'City', prop: 'consumer.shippingAddress.cityName', group: 'SHIPPING', order: 1 },
            { displayName: 'State', prop: 'consumer.shippingAddress.stateName', group: 'SHIPPING', order: 1 },
            { displayName: 'Country code', prop: 'consumer.shippingAddress.countryCode', group: 'SHIPPING', order: 1 },
            { displayName: 'Phone number', prop: '', group: 'SHIPPING', order: 1 },
            { displayName: 'fax', prop: '', group: 'SHIPPING', order: 1 },
            { displayName: 'Email', prop: '', group: 'SHIPPING', order: 1 },
            { displayName: 'Email type ind', prop: '', group: 'SHIPPING', order: 1 },
            // Airline - Flight
            { displayName: 'Airline Code', prop: 'flightData.airlineCode', group: 'FLIGHT', order: 1 },
            { displayName: 'Airline Name', prop: 'flightData.airlineName', group: 'FLIGHT', order: 1 },
            { displayName: 'Flight Date', prop: 'flightData.flightDate', group: 'FLIGHT', order: 1 },
            { displayName: 'Number of Legs', prop: 'flightData.numberOfLegs', group: 'FLIGHT', order: 1 },
            { displayName: 'Flight legs', prop: 'flightData.flightLegs', group: 'FLIGHT', order: 1, children: [
                { displayName: 'Leg Number', prop: 'flightData.flightLegs.seqId', group: 'FLIGHT', order: 1 },
                { displayName: 'Stopover code', prop: 'flightData.flightLegs.stopoverCode', group: 'FLIGHT', order: 1 },
                { displayName: 'Departure Airport', prop: 'flightData.flightLegs.originAirport', group: 'FLIGHT', order: 1 },
                { displayName: 'Arrival Airport', prop: 'flightData.flightLegs.arrivalAirport', group: 'FLIGHT', order: 1 },
                { displayName: 'Departure Date', prop: 'flightData.flightLegs.legDate', group: 'FLIGHT', order: 1 },
                { displayName: 'Departure Time', prop: 'flightData.flightLegs.departureTime', group: 'FLIGHT', order: 1 },
                { displayName: 'Airline class', prop: 'flightData.flightLegs.airlineClass', group: 'FLIGHT', order: 1 },
                { displayName: 'Fare basis', prop: 'flightData.flightLegs.fareBasisCode', group: 'FLIGHT', order: 1 },
                { displayName: 'Carrier code', prop: 'flightData.flightLegs.carrierCode', group: 'FLIGHT', order: 1 }
            ]},
            // Airline - Ticket
            { displayName: 'PNR', prop: 'flightData.pnr', group: 'TICKET', order: 1 },
            { displayName: 'Invoice Number', prop: 'flightData.airlineInvoiceNumber', group: 'TICKET', order: 1 },
            { displayName: 'Ticket Number', prop: 'flightData.ticketNumber', group: 'TICKET', order: 1 },
            { displayName: 'Agent Number IC code', prop: 'flightData.agentNumericCode', group: 'TICKET', order: 1 },
            { displayName: 'E ticket indicator', prop: 'flightData.eticketInd', group: 'TICKET', order: 1 },
            { displayName: 'Point of Sale', prop: 'flightData.pointOfSaleName', group: 'TICKET', order: 1 },
            { displayName: 'POS City Code', prop: 'flightData.posCityCode', group: 'TICKET', order: 1 },
            { displayName: 'Place of Issue', prop: 'flightData.placeOfIssueName', group: 'TICKET', order: 1 },
            { displayName: 'Restricted ticket indicator', prop: 'flightData.restrictedTicketInd', group: 'TICKET', order: 1 },
            { displayName: 'Issue date', prop: 'flightData.issueDate', group: 'TICKET', order: 1 },
            { displayName: 'Delivery method', prop: 'flightData.ticketDeliveryMethodCode', group: 'TICKET', order: 1 },
            { displayName: 'Class', prop: 'flightData.classCode', group: 'TICKET', order: 1 },
            // Airline - Passenger
            { displayName: 'Passenger Name', prop: 'flightData.passengerName', group: 'PASSENGER', order: 1 },
            { displayName: 'Is Third Party?', prop: 'flightData.isThirdPartyInd', group: 'PASSENGER', order: 1 },
            { displayName: 'Is Registered Customer?', prop: 'flightData.isRegisteredCustomerInd', group: 'PASSENGER', order: 1 },
            { displayName: 'Itenary Customer ID', prop: 'flightData.customerId', group: 'PASSENGER', order: 1 },
        ];
    }

    // TODO fill up the fields with respect to group
    public static get financialDetailsColumnMap(): Array<any> {
        return [
            { displayName: 'Entities', prop: 'remittanceRelationId', group: 'ENTITIES', order: 1 },
            { displayName: 'Currency', prop: 'currency', group: 'ENTITIES', order: 1 },
            { displayName: 'merchant', prop: 'merchantId', group: 'MERCHANTS', order: 1 },
            { displayName: 'Merchant Ids', prop: 'merchantIds', group: 'MERCHANTS', order: 1 },
            { displayName: 'Date', prop: 'productionDate', group: 'REMITTANCE_TOTAL', order: 1 },
            { displayName: 'Remittance Amount', prop: 'totalRemittanceAmount', group: 'REMITTANCE_TOTAL', order: 1 },
            { displayName: 'Currency', prop: 'remittanceCurrencyCode', group: 'OPERATIONAL_OVERVIEW', order: 1 },
            { displayName: 'Merchant Ids', prop: 'merchantIds', group: 'OPERATIONAL_OVERVIEW', order: 1 },
            { displayName: 'Remittance Amount', prop: 'totalRemittanceAmount', group: 'OPERATIONAL_OVERVIEW', order: 1 },
            { displayName: 'Message', prop: 'remittanceMessage', group: 'OPERATIONAL_OVERVIEW', order: 1 },
            { displayName: 'Collected Amount', prop: 'totalCollectedAmount', group: 'OPERATIONAL_OVERVIEW', order: 1 },
            { displayName: 'Reversal Amount', prop: 'totalReversalAmount', group: 'OPERATIONAL_OVERVIEW', order: 1 }, 
            { displayName: 'Production Date', prop: 'productionDate ', group: 'OPERATIONAL_OVERVIEW', order: 1 }, 
            { displayName: 'Total Transactions', prop: 'totalTransactionCount', group: 'OPERATIONAL_OVERVIEW', order: 1 },
            { displayName: 'Merchant Id', prop: 'merchantId', group: 'OPERATIONAL_DETAILS', order: 1 },
            { displayName: 'Transaction Currency', prop: 'transactionCurrencyCode', group: 'OPERATIONAL_DETAILS', order: 1 },
            { displayName: 'Currency', prop: 'remittanceCurrencyCode', group: 'OPERATIONAL_DETAILS', order: 1 },
            { displayName: 'Transaction Amount', prop: 'transactionAmount', group: 'OPERATIONAL_DETAILS', order: 1 },
            { displayName: 'FX rate', prop: 'appliedFx', group: 'OPERATIONAL_DETAILS', order: 1 },
            { displayName: 'Collected Amount', prop: 'collectedAmount', group: 'OPERATIONAL_DETAILS', order: 1 },
            { displayName: '# of Transactions', prop: 'transactionCount', group: 'OPERATIONAL_DETAILS', order: 1 },
        ];
    }

    public static get api(): Array<any> {
        return [
            {name: 'PAYMENTS', api: '/graphql'},
            {name: 'PAYMENT_DETAILS', api: '/graphql'},
            {name: 'FILTER', api: '/graphql'},
            {name: 'FILTER_VALUES', api: '/graphql'},
            {name: 'USER_CONFIG', api: '/userconfiguration'},
            {name: 'USER', api: '/users'},
            {name: 'RESET_PASSWORD', api: '/users/resetPassword'},
            {name: 'MERCHANTS', api: '/merchants/search'},
            {name: 'MERCHANT_ACCOUNTS', api: '/merchants/accounts/search'},
            {name: 'MERCHANT_IDS', api: '/merchants/hierarchy/accounts'},
            {name: 'MERCHANT_GROUPS', api: '/merchants/accounts/groups/aggregated/search'},
            {name: 'MERCHANT_GROUP', api: '/merchants/accounts/groups'},
            {name: 'ALL_USERS', api: '/users/aggregated/search'},
            {name: 'ALL_USERS_FOR_GROUP', api: '/users/regular/search'},
            {name: 'ASSIGN_USERS_TO_GROUP', api: '/merchants/hierarchy/groups'},
            {name: 'PBI_EMBED_TOKEN', api: '/powerbi/reports'},
            {name: 'EXPORT_CSV', api: '/graphql/export'},
            {name: 'ANALYTICS', api: '/dashboard'},
            {name: 'FINANCIAL_REMITTANCE_TOTAL', api: '/financials'},
            {name: 'FINANCIAL_OPERATIONAL_OVERVIEW', api: '/financials'},
            {name: 'FINANCIAL_OPERATIONAL_DETAIL', api: '/financials'},
        ];
    }

    public static getApi(s: string): string {
        return AppConstants.api.find( (a) => s === a.name ).api;
    }

    public static get gqlSortableColumnsMap(): Object {
        return {
            'merchant.accountId': 'merchantId',
            'effortId': 'effortId',
            'orderId': 'orderId',
            'attemptId': 'attemptId',
            'createdDate': 'createdDate',
            'currentStatus.transactionStatus': 'transactionStatus',
            'consumer.customerId': 'customerId',
            'paymentInstrument.last4Digits': 'last4Digits',
            'paymentInstrument.profileToken': 'profileToken',
            'consumer.email': 'consumerEmail',
            'consumer.ipAddress': 'consumerIp',
            'externalServices.authenticationInd': 'externalServiceAuthenticationInd',
            'authenticationTry.validationResult': 'result3ds',
            'requestData.recurringInd': 'recurringInd',
            'currentStatus.statusId': 'statusCode',
            'requestData.requestCountryName': 'country',
            'paymentMethod.name': 'paymentMethod',
            'paymentProduct.name': 'paymentProduct',
            'paidAmount.currencyCode': 'currencyCode',
            'requestData.requestAmount': 'amount',
            'merchantOrderId': 'merchantOrderId'
        };
    }

    public static get adminColumnMap(): Array<any> {
        return [
            {displayName: 'First Name', sortable: true },
            {displayName: 'Last Name', sortable: true },
            {displayName: 'Email' },
            {displayName: 'Merchant' },
            {displayName: 'Group' },
            {displayName: 'Admin user' },
            {displayName: 'Last access' },
            {displayName: 'Actions' },
          ];
    }

    public static get rejectionReasonsMap(): Array<any> {
        return [
            { eventid: 102040, description: "INCORRECT_USERNAMEPASSWORD", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 210330, description: "PAYMENTPRODUCT_NOT_VALID_FOR_ORDERTYPE", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 300360, description: "ORDER_NOT_FOUND_OR_UPDATE_NOT_POSSIBLE_WITH_CURRENT_STATUS", action_to_take: "Adjust request", order_of_occurrence: 4},
            { eventid: 300420, description: "MERCHANT_PARAMETER_NOT_FOUND", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 300421, description: "PAYMENTPRODUCT_PARAMETER_NOT_FOUND", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 300422, description: "MERCHANT_PAYMENTPRODUCT_PARAMETER_NOT_FOUND", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400010, description: "CONTRACTID_NOT_FOUND_FOR_THIS_COMBINATION", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400040, description: "DOPAYMENT_METHOD_INVOCATION_EXCEPTION", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400060, description: "EXECSTORED_RETURNED_NULL", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 400230, description: "INSERTATTEMPT_FAILED_OTHER_SESSION_ACTIVE", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400280, description: "ORIGINAL_PAYMENT_NOT_FOUND", action_to_take: "Adjust request", order_of_occurrence: 4},
            { eventid: 400300, description: "PAYMENTATTEMPT_NOT_FOUND", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400400, description: "PROCESS_REFUND_FAILED", action_to_take: "Adjust request", order_of_occurrence: 4},
            { eventid: 400490, description: "INSERTATTEMPT_MAX_NR_OF_ATTEMPTS_REACHED", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400530, description: "INVALID_PAYMENTPRODUCTID_FOR_MERCHANT", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400600, description: "PAYMENT_ATTEMPT_COULD_NOT_BE_FOUND", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400620, description: "CARD_NOT_VALIDATED", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400630, description: "INVALID_PARES", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400650, description: "AUTHENTICATION_ERROR_INVALID_DATA", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400660, description: "AUTHENTICATION_ERROR_MISSING_FIELD", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400670, description: "AUTHENTICATION_ERROR_INVALID_CARD", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400706, description: "PROCESSRETURNED_INVALID_AMOUNT", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 400707, description: "ERROR VERIFICATION_FAILED", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 400708, description: "TRANSACTION_EXPIRED", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 400730, description: "RABO_RABOSIGNEDURL_MISSING", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 400750, description: "PAGO_INVALID_ACCOUNT", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400751, description: "GIROPAY_INVALID_ACCOUNT", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400760, description: "PAGO_ACCOUNT_NOT_SUPPORTED", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400761, description: "GIROPAY_ACCOUNT_NOT_SUPPORTED", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400780, description: "PAGO_INVALID_BANKCODE", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400781, description: "GIROPAY_INVALID_BANKCODE", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400791, description: "GIROPAY_ACCOUNT_NOT_SUPPORTED2", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400793, description: "PAGO_ALREADY_AUTHORIZED", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 400794, description: "PAGO_AUTHORIZATION_IN_PROCESS", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 400800, description: "ING_HOMEPAY_INVALID_LANGUAGECODE", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400810, description: "ING_HOMEPAY_INVALID_HASH", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 400840, description: "IDEAL_INVALID_XML", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 400970, description: "IDEAL_INVALID_EXPIRATIONPERIOD", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 401011, description: "UNKNOWN_DEVICE", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 401012, description: "UNSUPPORTED_DEVICE", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 401014, description: "EXPIRED_CARD", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 401015, description: "INVALID_CARD_NUMBER", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 401016, description: "INVALID_TRANSACTION", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 401017, description: "NO_CARD_RECORD", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 401029, description: "NON_PAYMENT_TRANSACTION_NOT_SUPPORTED", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 401030, description: "REQUESTOR_INITIATED_TRANSACTION_NOT_SUPPORTED", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 410000, description: "PRODUCTFIELDMETADATA_NOT_FOUND_FOR_THIS_PAYMENTPRODUCTID", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 410120, description: "AMOUNT_NOT_BETWEEN_MINAMOUNT_AND_MAXAMOUNT", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 420040, description: "NO_BANK_INFORMATION_FOUND_FOR_THIS_BANK", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 420070, description: "IBAN/BBAN MISMATCH", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 420080, description: "INVALID IBAN", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 430195, description: "BAD_TRACK_2", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 430321, description: "INCOMPLETE_TRANSACTION", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 430327, description: "INVALID_AMOUNT", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 430460, description: "RECURRING_NOT_ALLOWED_FOR_PRODUCT", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 430465, description: "AUTH_TYPE_NOT_ALLOWED", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 430470, description: "ORIGINAL_AMOUNT_INCORRECT", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 430572, description: "DEPOSIT_IS_ALREADY_REFERENCED_BY_A_CHARGEBACK", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 430573, description: "INVALID_PAYMENT_TYPE", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 430595, description: "INVALID_DATA", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 450000, description: "BANKCODE_IS_REQUIRED_FOR_COUNTRY", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 450060, description: "BANKCHECKDIGIT_IS_REQUIRED_FOR_COUNTRY", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 450070, description: "BRANCHCODE_IS_REQUIRED_FOR_COUNTRY", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 450090, description: "DATECOLLECT_MUST_BE_X_DAYS_FROM_CURRENTDATE", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 450120, description: "ACCOUNTNUMBER_HAS_INVALID_LENGTH", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 450130, description: "INVALID_BANKCODE_FOR_PRODUCT_COUNTRY_COMBINATION", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 450140, description: "BANKCODE_DOES_NOT_MATCH_BANKNAME", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 450180, description: "NOT_RETRYABLE", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 480022, description: "CUSTOMER_ID_MISSING", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 480023, description: "INVALID_UCOF_PARAMETER", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 480024, description: "INVALID_UCOF_COMBINATION", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 485000, description: "NEW_ACCOUNT_INFO_AVAILABLE", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 530000, description: "SERVICE_NOT_ALLOWED_FOR_MERCHANT", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 700540, description: "PROFILE_NOT_FOUND", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 700700, description: "INVALID_SEPA_COUNTRY", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 700710, description: "MANDATE_MANDATORY", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 700720, description: "INVALID_TRANSACTIONTYPE", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 800180, description: "INVALID_CVVINDICATOR", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 800200, description: "INVALID_CPF_CNPJ", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 800210, description: "INVALID_ECI", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 800220, description: "INVALID BIC", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 800230, description: "INVALID INPUT FIELD", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 800240, description: "INVALID INDICATOR VALUE", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 1200000, description: "RESPONSE_PARSE_ERROR", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 1200010, description: "VERIFY_PAYMENT_FAILED", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 2000000, description: "INVALID MD5", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 2800000, description: "PAYMENT_AMOUNT_DECIMAL_FRACTION_ERROR", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 3001000, description: "INVALID_BANK_DATA", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 4310001, description: "RTAUSTRIA_INVALID_TRANSACTIONNOKURL", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4310005, description: "RTAUSTRIA_INVALID_AMOUNT", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4380060, description: "BRASPAG_INVALID_RETRY", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 4380070, description: "BOLETO_INVALID_PARAMETER", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4380080, description: "BOLETO_MISSING_PARAMETER", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4380100, description: "BOLETO_NOT_SUPPORTED", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4434000, description: "ORDERDESCRIPTION_EXCEEDS_127_BYTES", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4434460, description: "PAYPAL_TRANSACTION_REFUSED", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4434482, description: "COULD_NOT_REFUND", action_to_take: "Adjust request", order_of_occurrence: 4},
            { eventid: 4500000, description: "UNKNOWN_ISSUERID", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4500240, description: "WEBMONEY_INVALID_REFUNDAMOUNT", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4500250, description: "WEBMONEY_REFUND_TOO_LATE", action_to_take: "Adjust request", order_of_occurrence: 4},
            { eventid: 4500260, description: "WEBMONEY_ALREADY_REFUNDED", action_to_take: "Adjust request", order_of_occurrence: 2},
            { eventid: 4500640, description: "BOKU_INVALID_PRICEPOINT", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 18000800, description: "EXTERNALID_NOT_FOUND_IN_REQUEST", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 18001200, description: "NO_RATES_FOUND_FOR_CURRENCYCODE", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 18001300, description: "NO_RATESETS_FOUND_FOR", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 20000000, description: "PARAMETER_NOT_FOUND_IN_REQUEST", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 20000099, description: "PARAMETER_IS_NOT", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 20000110, description: "PARAMETER_IS_NOT_AN_INTEGER", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 20001000, description: "NULL_VALUE_NOT_ALLOWED_FOR_THIS_ATTRIBUTE", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000000, description: "UNKNOWN_DATATYPE", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000010, description: "INVALID_CARDNUMBER_NUMBERCHECK", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 21000020, description: "INVALID_CARDNUMBER_LUHNCHECK", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 21000030, description: "INVALID_CAVV", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 21000040, description: "INVALID_DATETIME_YYYYMMDDHH24MISS", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000050, description: "INVALID_NUMBER", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000070, description: "INVALID_VARCHAR_LENGTH", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000080, description: "INVALID_COUNTRYCODE", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000090, description: "INVALID_CURRENCYCODE", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000100, description: "INVALID_LANGUAGECODE", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000110, description: "INVALID_CURRENCYCODE_FOR_MERCHANT", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000120, description: "INVALID_EXPIRYDATE_MMYY", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 21000130, description: "INVALID_EMAIL_ADDRESS", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000150, description: "INVALID_BANK_ACCOUNTNUMBER", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 21000155, description: "INVALID_BANK_CODE", action_to_take: "Adjust request", order_of_occurrence: 3},
            { eventid: 21000200, description: "INVALID_SEX", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000210, description: "INVALID_DATE_YYYYMMDD", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000220, description: "FIELD_LENGTH_INVALID", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000230, description: "FIELD_LENGTH_TOO_LONG", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 21000240, description: "FIELD_LENGTH_TOO_SHORT", action_to_take: "Adjust request", order_of_occurrence: 1},
            { eventid: 10004, description: "INVALID_INPUT_DATA", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 90000, description: "UNABLE_TO_PROCESS", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 90007, description: "ERROR_IN_PROCESSING_THE_TRANSACTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 300660, description: "MERCHANT_SERVICE_PARAMETER_NOT_FOUND", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 400170, description: "DOPAYMENT_FAILED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 400540, description: "SCENARIO_NOT_SUCCESSFUL", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 400709, description: "TRANSACTION_WAS_REJECTED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 400740, description: "QIWI_INIT_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 400745, description: "QIWI_UNKNOWN_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 400770, description: "POLL_RETURNED_NO_PAYMENTSTATUSRESULT", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 400792, description: "PAGO INVALID MERCHANTNUMBER", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 400860, description: "IDEAL_SECURITY_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 400870, description: "IDEAL_FIELD_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 400880, description: "IDEAL_APPLICATION_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 400885, description: "IDEAL_UNKNOWN_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 401018, description: "SECURITY_FAILURE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 410070, description: "DATBASEMANAGER_RETURNED_NULL_ON_COPYORDERLINES", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 410100, description: "COPY_ORDERLINES_RETURNED_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 420000, description: "NO_BANK_FOUND_FOR_THIS_COUNTRY", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 420005, description: "NO_CURRENCY_FOUND_FOR_THIS_COUNTRY", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 420010, description: "NO_CURRENCY_FOUND_FOR_THIS_COUNTRY", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 420030, description: "NO_SUITABLE_BANK_FOUND_FOR_THIS_COMBINATION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 420060, description: "NO_VIRTUAL_ACCOUNT_FOUND_FOR_THIS_COUNTRY", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 430275, description: "CONFIGURATION_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430282, description: "INVALID_RESPONSE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430284, description: "SUSPECTED_MALFUNCTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430288, description: "UNABLE_TO_LOCATE_RECORD_ON_FILE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430300, description: "DUPLICATE_TRANSMISSION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430309, description: "FIELDS_SYNTAX_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430312, description: "FOREIGN_NETWORK_FAILURE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430315, description: "FORMAT_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430324, description: "INCORRECT_PIN_NA", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430339, description: "INVALID_MERCHANT_OR_SERVICE_PROVIDER", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430348, description: "ISSUER_NOT_FOUND_UNABLE_TO_ROUTE_AT_AEM", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430349, description: "TRANSACTION_CANNOT_BE_COMPLETED_VIOLATION_OF_LAW", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430363, description: "MANDATORY_FIELDS_MISSING", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430372, description: "MAXIMUM_OFFLINE_REFUND_REACHED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 430375, description: "MAXIMUM_ONLINE_REFUND_REACHED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 430424, description: "SYSTEM_MALFUNCTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430685, description: "TERM DEACTIVATED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430689, description: "RECORD_NOT_FOUND", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430900, description: "NO_VALID_PROVIDERS_FOUND_FOR_COMBINATION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430920, description: "MERCHANTNUMBER_NOT_FOUND_FOR_THIS_COMBINATION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 430940, description: "NO_TERMINALID_FOUND_FOR_COMBINATION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 433910, description: "SIGNING_EXCEPTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 434020, description: "INTERAC_DO_NOT_HONOUR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 434030, description: "INTERAC_SYSTEM_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 434090, description: "INTERAC_FIELD_MISSING", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 439000, description: "INTERCARD_SYSTEM_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 440000, description: "NO_CHEQUEADDRESSID_FOUND_FOR_THIS_COUNTRY_AND_CURRENCY", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 480000, description: "DATA_NOT_FOUND_IN_GPM_MESSAGEDATA", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 480010, description: "DATA_NOT_FOUND_IN_GPM_MESSAGEDATA4", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 490000, description: "COMPLIANCE_RESTRICTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 530010, description: "CYBERSOURCE_EXCEPTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 700660, description: "SERVICE_NOT_ALLOWED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 836000, description: "SOFORT_APPLICATION_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 882000, description: "CITRUS_APPLICATION_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4310004, description: "RTAUSTRIA_INVALID_AUTHORIZATION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4310007, description: "RTAUSTRIA_INVALID_XML", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4310009, description: "RTAUSTRIA_SYSTEM_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4312500, description: "CASHU REFUND RESPONSE ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4312600, description: "CASHU REFUND ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4320030, description: "IPS_VERIFY_EXCEPTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4360000, description: "ECARD_NO_HASH_RETURNED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4380000, description: "BRASPAG_NO_URL_RECEIVED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4380010, description: "BRASPAG_DUPLICATE_REFUNDID", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 4},
            { eventid: 4380030, description: "BRASPAG_UNKNOWN_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 4},
            { eventid: 4380040, description: "BRASPAG_DATABASE_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 4},
            { eventid: 4380095, description: "REQUEST {0} BOLETO SERVICE PROVIDER ERROR - CODE {1}", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4432000, description: "APPLICATION_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4432100, description: "PAYSAFECARD_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4433105, description: "UKASH_FAILED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4433200, description: "UKASH_UNDOCUMENTED_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4434100, description: "PAYPAL_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4434400, description: "PAYPAL_INTERNAL_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4434410, description: "PAYPAL_AUTHENTICATION_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4434420, description: "PAYPAL_INVALID_ARGUMENT", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4434440, description: "PAYPAL_NO_PERMISSION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4434470, description: "PAYPAL_UNKNOWN_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4500020, description: "SECUREVAULT_APPLICATION_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4500100, description: "KONBINI_APPLICATION_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4500210, description: "WEBMONEY_NO_RETVAL_IN_RESPONSE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4500220, description: "WEBMONEY_UNKNOWN_INWMTRANID", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4500270, description: "WEBMONEY_INVALID_CERTIFICATE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4500280, description: "WEBMONEY_UNKNOWN_RETVAL", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4500500, description: "INVALID RUT", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 2},
            { eventid: 4510120, description: "PURCHASE_FAILED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4510200, description: "ERROR_FUNCTION_ACCESS_DENIED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4510230, description: "ERROR_INVALID_CREDENTIALS", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 4510280, description: "ERROR_ENDUSER_IS_BLOCKED", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 8610000, description: "ALIPAY_REFUND_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 8830000, description: "ERROR_INVALID_MERCHANT_KEY_IV_OR_MERCHANTCODE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 15000960, description: "EVENT_EXECUTE_EXCEPTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 15800000, description: "NO_METADATA_FOUND_FOR_TABLE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 15800130, description: "UNKNOWN_MODIFICATIONTYPE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 15911400, description: "COULD_NOT_START_DATABASE_TRANSACTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 22000010, description: "NO_DRIVER_FOR_COMMUNICATION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 22000020, description: "COULD_NOT_WRITE_REQUEST", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 22000030, description: "COULD_NOT_READ_RESPONSE", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 22000040, description: "COMMUNICATION_ERROR", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 23000000, description: "COULD_NOT_CONNECT_TO_THIRD_PARTY", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 3},
            { eventid: 90000010, description: "UNEXPECTED_EXCEPTION", action_to_take: "Contact Ingenico ePayments", order_of_occurrence: 1},
            { eventid: 400705, description: "REJECTED_BY_BANK", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 401010, description: "CARD_AUTHENTICATION_FAILED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 401019, description: "STOLEN_CARD", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 401020, description: "SUSPECTED_FRAUD", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 401021, description: "TRANSACTION_NOT_PERMITTED_TO_CARDHOLDER", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430165, description: "ALLOWABLE_PIN_TRIES_EXCEEDED_NA", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430175, description: "AMOUNT_TOO_LARGE", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430185, description: "AUTHENTICATION_FAILURE_NA", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430190, description: "FAILED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430200, description: "BANK_NOT_SUPPORTED_ISSUER_SIGNOFF", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430260, description: "CARD_ACCEPTOR_CALL_ACQUIRERS_SECURITY_DEP", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430285, description: "DO_NOT_HONOR", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430303, description: "ERROR", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430306, description: "EXPIRED_CARD", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430318, description: "HONOR_WITH_IDENTIFICATION", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430330, description: "INVALID_CARD_NUMBER", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430333, description: "INVALID_CARD_RANGE", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430336, description: "INVALID_ISSUE_NUMBER", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430342, description: "INVALID_START_DATE", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430345, description: "INVALID_TRANSACTION", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430351, description: "ISSUER_OFF_LINE", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430354, description: "ISSUER_UNKNOWN", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430357, description: "LOST_OR_STOLEN_CARD", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430360, description: "LOW_FUNDS", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430381, description: "NETWORK_ERROR", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430384, description: "NO_ACCOUNT", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430385, description: "REQUEST_FUNCTION_NOT_SUPPORTED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430386, description: "NO_UNIVERSAL_ACCOUNT", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430387, description: "NO_ACTION_TAKEN", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430389, description: "NO_CHEQUING_ACCOUNT", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430390, description: "NO_CARD_RECORD", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430391, description: "NO_SAVING_ACCOUNT", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430396, description: "NOT_PERMITTED_TO_CARDHOLDER", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430399, description: "NOT_PERMITTED_TO_TERMINAL", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430406, description: "RECONCILE_ERROR_REVERSAL", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430409, description: "REFERRED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430412, description: "RESTRICTED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430415, description: "SECURITY_VIOLATION", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430418, description: "STOLEN_CARD", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430421, description: "SUSPECTED_FRAUD", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430436, description: "TRANSACTION_FAILED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430439, description: "TRANSACTION_TYPE_NOT_ALLOWED_FOR_MERCHANT", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430445, description: "WRONG_PIN_ALLOWABLE_PIN_TRIES_EXCEEDED_NA", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430450, description: "DENIED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430451, description: "DENIED_BLACKLIST", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430452, description: "DENIED_VELOCITY_CHECK", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430454, description: "DENIED_REPEATED_REVERSALS", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430455, description: "DENIED_CUSTOMER_ACCOUNT_STATUS", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430456, description: "DENIED_CUSTOMER_ADDRESS_STATUS", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430475, description: "OTHERS", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430480, description: "OVER_FLOOR_LIMIT", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430485, description: "PIN_VALIDATION_NOT_POSSIBLE_NA", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430490, description: "PICK_UP_CARD", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430495, description: "PICK_UP_CARD_SPECIAL_CONDITION_OTHER_STOLENLOST", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430687, description: "CVV_SOFT_FAILURE", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430688, description: "INVALID_TERMINAL_ID", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430692, description: "CVV2_DECLINED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430694, description: "INVALID_CARD_VERIFICATION_VALUE", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430697, description: "SUSPECTED_FRAUD", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430699, description: "INVALID_EXPIRATION_DATE", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 430701, description: "INVALID_MOP/UNAUTHORIZED_USER", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 4360024, description: "ECARD_REFUSED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 4510110, description: "GETCARD_FAILED", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 4510150, description: "BALANCE INSUFFICIENT", action_to_take: "Declined", order_of_occurrence: 3},
            { eventid: 401024, description: "LOW_CONFIDENCE", action_to_take: "Fraud", order_of_occurrence: 3},
            { eventid: 430410, description: "CHALLENGED", action_to_take: "Fraud", order_of_occurrence: 3},
            { eventid: 485020, description: "DO_NOT_TRY_AGAIN", action_to_take: "Fraud", order_of_occurrence: 3},
            { eventid: 4360023, description: "ECARD_ONLINE_FRAUD", action_to_take: "Fraud", order_of_occurrence: 3},
            { eventid: 400420, description: "Consumer revocation of all authorization order", action_to_take: "No action required", order_of_occurrence: 4},
            { eventid: 400697, description: "STATUS_ALREADY_FINAL", action_to_take: "No action required", order_of_occurrence: 2},
            { eventid: 400700, description: "CANCELLED_AT_BANK", action_to_take: "No action required", order_of_occurrence: 3},
            { eventid: 400701, description: "CANCELLED_BY_BANK", action_to_take: "No action required", order_of_occurrence: 3},
            { eventid: 400702, description: "FAILED_AT_BANK", action_to_take: "No action required", order_of_occurrence: 3},
            { eventid: 400704, description: "UNKNOWN_AT_BANK", action_to_take: "No action required", order_of_occurrence: 3},
            { eventid: 4433000, description: "UKASH_DECLINED", action_to_take: "No action required", order_of_occurrence: 2},
            { eventid: 4433100, description: "UKASH_FAILED", action_to_take: "No action required", order_of_occurrence: 2},
            { eventid: 90001, description: "ERROR_IN_PROCESSING_THE_TRANSACTION", action_to_take: "Try again later", order_of_occurrence: 1},
            { eventid: 90012, description: "UNABLE_TO_AUTHORIZE", action_to_take: "Try again later", order_of_occurrence: 3},
            { eventid: 400850, description: "IDEAL_SYSTEM_MAINTENANCE", action_to_take: "Try again later", order_of_occurrence: 2},
            { eventid: 401013, description: "EXCEED_AUTHENTICATION_FREQUENCY_LIMIT", action_to_take: "Try again later", order_of_occurrence: 1},
            { eventid: 401022, description: "CARDHOLDER_NOT_ENROLLED", action_to_take: "Try again later", order_of_occurrence: 1},
            { eventid: 401023, description: "TRANSACTION_TIMEOUT_AT_ACS", action_to_take: "Try again later", order_of_occurrence: 1},
            { eventid: 401025, description: "MEDIUM_CONFIDENCE", action_to_take: "Try again later", order_of_occurrence: 1},
            { eventid: 401033, description: "SCA_ALREADY_UNDERTAKEN", action_to_take: "Try again later", order_of_occurrence: 1},
            { eventid: 430025, description: "SCA_IS_REQUIRED", action_to_take: "Try again later", order_of_occurrence: 1},
            { eventid: 430400, description: "REENTER", action_to_take: "Try again later", order_of_occurrence: 3},
            { eventid: 430403, description: "REENTER_TRANSACTION", action_to_take: "Try again later", order_of_occurrence: 3},
            { eventid: 430427, description: "TIMEOUT_AT_IEM", action_to_take: "Try again later", order_of_occurrence: 3},
            { eventid: 430430, description: "TIMEOUT", action_to_take: "Try again later", order_of_occurrence: 3},
            { eventid: 430433, description: "TOO_MUCH_USAGE", action_to_take: "Try again later", order_of_occurrence: 3},
            { eventid: 485010, description: "TRY_AGAIN_LATER", action_to_take: "Try again later", order_of_occurrence: 1},
            { eventid: 4500600, description: "BOKU ERROR", action_to_take: "Try again later", order_of_occurrence: 3},
            { eventid: 4500700, description: "SUB1 ERROR", action_to_take: "Try again later", order_of_occurrence: 3},
            { eventid: 22000045, description: "COMMUNICATION_ERROR", action_to_take: "Try again later", order_of_occurrence: 3},
            { eventid: 9999999999, description: "ERROR_IN_PROCESSING_THE_REQUEST", action_to_take: "Try again later", order_of_occurrence: 1},
            { eventid: 4390030, description: "", action_to_take: "", order_of_occurrence: ""},
            { eventid: 8650010, description: "", action_to_take: "", order_of_occurrence: ""},
            { eventid: 8690005, description: "", action_to_take: "", order_of_occurrence: ""}
          ];
    }

}
