import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { get } from 'lodash';
import { AppService } from '../../service/app.service';
import { minDigitsValidator, minLowerCaseValidator, minUpperCaseValidator, mustMatch, specialCharacter } from '../../utils/form-validators';
import { ToastService } from '../../service/toast.service';
import { TranslateService } from '../../service/translate.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userDetails: KeycloakProfile;
  firstName: string;
  lastName: string;
  showUserDetails = false;
  changePwdForm;
  status = {
    valid: false,
    invalid: false,
    message: ''
  };

  constructor(private keycloakService: KeycloakService, public api: AppService, public translate: TranslateService,
    private modalService: NgbModal, private formBuilder: FormBuilder, public ts: ToastService,
    public uS: UserService) {}

  ngOnInit() {
    this.keycloakService.loadUserProfile().then( userDetails => {
      this.userDetails = userDetails;
      this.userDetails['id'] = get(this.keycloakService.getKeycloakInstance(), 'subject');
    });
    this.firstName = this.uS.userDetails.firstName;
    this.lastName = this.uS.userDetails.lastName;
    
    this.changePwdForm = new FormGroup({
      'oldPwd': new FormControl('', [Validators.required]),
      'newPwd': new FormControl('',
        [Validators.required, Validators.minLength(8), minDigitsValidator(1),
        minLowerCaseValidator(1), minUpperCaseValidator(1), specialCharacter(1)]),
      'confirmPwd': new FormControl('', [Validators.required])
    },  mustMatch('newPwd', 'confirmPwd'));
  }

  get newPwd() { return this.changePwdForm.get('newPwd'); }
  get confirmPwd() { return this.changePwdForm.get('confirmPwd'); }
  get oldPwd() { return this.changePwdForm.get('oldPwd'); }

  logout() {
    this.keycloakService.logout(location.origin);
    this.showUserDetails = false;
  }

  toggleUserDetails() {
    this.showUserDetails = !this.showUserDetails;
  }

  openChangePasswordPopup(content) {
    this.changePwdForm.reset();
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      // console.log('blabla');
    }, (reason) => {
      // console.log('closing');
    });
    this.showUserDetails = false;
  }

  save(modal) {
    this.api.changePassword(this.userDetails.username, this.oldPwd.value, this.newPwd.value).subscribe((result: any) => {
      if (200 === result.status) {
        this.ts.success('Password changed successfully');
        this.closeModal(modal);
      }
    }, error => {
      const msg = error.error.message || 'Something went worng. Please try after sometime';
      this.ts.failure(this.translate.translateError(error));
    });
  }

  /* createUser() {
    this.api.createUser().subscribe((result) => {
      console.log('result:: ' + result);
    });
  } */

  isFormInvalid() {
    return this.changePwdForm.invalid;
  }

  isConfirmDisabled() {
    return this.changePwdForm.get('newPwd').invalid ? '' : null;
  }

  closeModal(modal) {
    modal.dismiss('Cross click');
  }
}
