import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from '../../service/user.service';
import { trigger, transition, animate, keyframes, style, state } from '@angular/animations';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger('enterLeave', [
      state('show', style({
        display: 'inline'
      })),
      state('hide', style({
        display: 'none'
      })),
      transition('show => hide', [
        animate('0.1s', keyframes([
          style({display: 'inline', offset: 0}),
          style({display: 'none', offset: 0.5}),
          style({display: 'none', offset: 1}),
        ]))
      ]),
      transition('hide => show', [
        animate('0.4s', keyframes([
          style({display: 'none', offset: 0}),
          style({display: 'none', offset: 0.5}),
          style({display: 'inline', offset: 1}),
        ]))
      ])
    ])
  ]
})
export class SideNavComponent implements OnInit, OnChanges {
  showAnalyticsSubMenu = false;
  showAnalyticsPOCSubMenu = false;
  showAuthorizationSubMenu = false;
  showAuthorizationPOCSubMenu = false;
  isAdmin = false;
  isIngenicoUser = false;
  hasElevateAccess = false;
  hasFinancialsAccess = false;
  // @Input() isCollapsed;
  @Output() collapseNav = new EventEmitter();
  @Output() mouseoveraction = new EventEmitter();
  @Input() fixedSideMenu;
  isCollapsed = false;

  constructor(public userApi: UserService, private api: AppService) { }

  ngOnInit() {
    if (this.userApi.userRights) {
      this.isAdmin = this.userApi.userRights.isAdmin;
      this.isIngenicoUser = this.userApi.userRights.ingenicoUser;
      this.hasElevateAccess = this.userApi.userRights.hasElevateAccess;
      this.hasFinancialsAccess = this.userApi.userRights.hasFinancialsAccess;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.fixedSideMenu.currentValue != changes.fixedSideMenu.previousValue) {
      if (changes.fixedSideMenu.currentValue === undefined || changes.fixedSideMenu.previousValue === undefined) { return; }
      // this.mouseaction(changes.fixedSideMenu.currentValue);
      this.isCollapsed = !changes.fixedSideMenu.currentValue;
    }
  }

  menuClick(option?, level?) {
    this.showAnalyticsSubMenu = false;
    this.showAnalyticsPOCSubMenu = false;
    this.showAnalyticsSubMenu = ('analytics' === option);
    this.showAnalyticsPOCSubMenu = ('analytics-poc' === option);
    this.showAuthorizationSubMenu = ('authorization' === level);
    this.showAuthorizationPOCSubMenu =('authorization-poc' === level);
  }

  getActiveClass(model) {
    return model.isActive ? 'icon-circle-1-functional-blue' : 'icon-circle-2';
  }

  mouseaction(value) {
    if (this.fixedSideMenu) { return; }
    this.isCollapsed = value;
    this.mouseoveraction.emit(value);
  }

  featureEnabled(flag) {
    return this.api.isFeatureEnabled(flag);
  }
}
