import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appClickAway]'
})
export class ClickAwayDirective {
  @Output() appClickAway = new EventEmitter<MouseEvent>();
  @Input() externalsClickAway = ''; // .selector1, #seletor2, selector;

  constructor(private elementRef: ElementRef) { } 

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const isSomeExternalsClicked = 
      this.externalsClickAway.split(',')
        .some(external => 
          external.length ?
          document.querySelector(external) && document.querySelector(external.trim()).contains(target) || target.classList.contains(external.trim().substr(1)) :
          false
        );

    if (target && !(this.elementRef.nativeElement.contains(target) || isSomeExternalsClicked)) {
      this.appClickAway.emit(event);
    }
  }

}
