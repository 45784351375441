import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

export function minDigitsValidator(numberOfDigits): ValidatorFn {
    return (control: AbstractControl) => {
        if (!control.value) { return null; }
        const forbidden = numberOfDigits <= (control.value.replace(/[^0-9]/g, '').length);
        return forbidden ? null : { 'minDigit': { value: control.value } };
    };
}

export function minLowerCaseValidator(numberOfChars): ValidatorFn {
    return (control: AbstractControl) => {
        if (!control.value) { return null; }
        const forbidden = numberOfChars > (control.value.replace(/[^a-z]/g, '').length);
        return forbidden ? { 'minLowerCase': { value: control.value } } : null;
    };
}

export function minUpperCaseValidator(numberOfChars): ValidatorFn {
    return (control: AbstractControl) => {
        if (!control.value) { return null; }
        const forbidden = numberOfChars > (control.value.replace(/[^A-Z]/g, '').length);
        return forbidden ? { 'minUpperCase': { value: control.value } } : null;
    };
}

export function mustMatch(control: string, matchControl: string): ValidatorFn {
    return (form: FormGroup) => {
        const pwd = form.controls[control];
        const confPwd = form.controls[matchControl];
        if (confPwd.errors && !confPwd.errors.mustMatch) {
            return;
        }
        if (pwd.value !== confPwd.value) {
            confPwd.setErrors({ 'mustMatch': true });
        } else {
            confPwd.setErrors(null);
        }
        return null;
    };
}

export function specialCharacter(numberOfChars): ValidatorFn {
    return (control: AbstractControl) => {
        if (!control.value) { return null; }
        const forbidden = numberOfChars > (control.value.replace(/[^?!#%$@]/g, '').length);
        return forbidden ? { 'specialChar': { value: control.value } } : null;
    };
}
