import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'searchPicklist'
})
export class SearchPicklistPipe implements PipeTransform {

  transform(list: Array<any>, text: string, prop: string): any {
    return list.filter((item) => {
      const regex = new RegExp(text, 'i');
      return text ? regex.test(get(item, prop)) : true;
    });
  }

}
