import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { findIndex, cloneDeep, debounce } from 'lodash';

@Component({
  selector: 'app-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss']
})
export class ComboBoxComponent implements OnInit {

  @Input() inList: Array<any>;
  @Input() maxNumberOfItems = 500;
  @Input() selectedList: Array<any> = [];
  @Output() onSelect = new EventEmitter();
  @Output() onSelectItem = new EventEmitter();
  @Input() limit = 2;
  @Input() displayProp = 'displayName';
  @Input() keyProp = 'id';
  @Input() name: String;
  showList = false;

  // variables for searching in list
  @Input() isSerchable = false;
  searchText = '';

  // Server side filtering variables
  @Input() isServerSideFilterable = false;
  @Output() applyFilterOnServer = new EventEmitter();

  // Selection for multiple option
  @Input() isMultiSelect = true;

  // Select all option only if it is multiselect
  @Input() isSelectAll = false;
  // isSelectAllChecked = false;

  timeoutId;


  constructor() { }

  ngOnInit() {
    this.searchText = '';
  }

  toggleselectItem(event, item) {
    // input type checkbox with label has issues to trigger events twice. this is a temp fix to avoid multiple triggers.
    if (event.target.classList.contains('custom-control-label')) { return; }

    const idx = findIndex(this.selectedList, (i) => {
      return item[this.keyProp] === i[this.keyProp];
    });

    this.onSelectItem.emit({item, isChecking: !(-1 < idx)});

    if (-1 < idx) {
      this.selectedList.splice(idx, 1);
      return true;
    } else {
      this.selectedList.push(item);
    }
    // this.isSelectAllChecked = (this.selectedList.length === this.inList.length);
  }

  isSelectAllChecked() {
    return (this.selectedList.length === this.inList.length);
  }

  selectSingleItem(event, item) {
    this.onSelectItem.emit([item]);
    this.selectedList.length = 0;
    this.selectedList.push(item);
    this.closeList();
  }

  toggleShowList() {
    this.showList = !this.showList;
    if (this.showList) {
      if(!this.isServerSideFilterable) {
        this.searchText = '';
      }
    } else {
      this.onSelect.emit(this.selectedList);
    }
  }

  closeList() {
    if (!this.showList) { return; }
    this.showList = false;
    this.onSelect.emit(this.selectedList);
  }

  isChecked(item) {
    const idx = findIndex(this.selectedList, (i) => {
      return item[this.keyProp] === i[this.keyProp];
    });
    return idx > -1;
  }

  stopPropagation(event) {
    event.preventDefault();
  }

  filterResults() {
    if (!this.isServerSideFilterable) { return; }
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      // console.log(this.searchText);
      this.applyFilterOnServer.emit({query: this.searchText});
    }, 500);
    /* debounce(() => {
      console.log(this.searchText);
      this.applyFilterOnServer.emit({query: this.searchText});
    }, 500); */
  }

  selectAll(event) {
    event.stopPropagation();
    const flag = (this.selectedList.length === this.inList.length);
    this.selectedList =  flag ? [] : [...this.inList];
    // this.isSelectAllChecked = !flag;
  }

  removeSelected(event, item) {
    event.stopPropagation();
    const f = this.toggleselectItem(event, item);
    if(f) {
      this.onSelect.emit(this.selectedList);
    }
  }
}
