<!--<div>
  <app-header></app-header>
  <div class="container-fluid app-container">
    <div class="row">
      <div class="in-nav col-lg-2">
        <app-side-nav></app-side-nav>
      </div>
      <div class="content col-lg-10">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>-->
<router-outlet></router-outlet>

