// TODO: Remove this file, not used any more
import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { AppService } from './app.service';
import { AppConstants } from '../constants/app-constants.constants';
import { HttpClient } from '@angular/common/http';
import { featureFlag } from '../utils/utility';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  isAuthenticated = false;
  userDetails: any;
  private _userPreferences: any;
  userRights: any;
  remittanceRelationIds: any;

  constructor(private keycloakService: KeycloakService, public api: AppService, public http: HttpClient) {

  }

  login(email: string, password: string): Observable<any> {
    // TODO - execute authentication api
    return Observable.create(ob => {
      setTimeout( () => {
        ob.next('true');
        this.isAuthenticated = true;
        ob.complete();

      }, 2000);
    });
  }

    /**
   * Load user details during app initialization
   */
  loadUserInfo(username) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.api.appConfig.baseUrl}${AppConstants.getApi('USER')}/${username}`).toPromise().then((response: any) => {
        const _response: any = response;
        this.userDetails.firstName = response.userAccount.firstName;
        this.userDetails.lastName = response.userAccount.lastName;
        this.userRights = {
          isAdmin: _response.userAccount.admin,
          ingenicoUser: _response.userAccount.ingenicoUser,
          canSeeUserDetails: _response.userAccount.userProfile.insightFunctions.filter(item => item.id === 1).length > 0,
          hasElevateAccess: _response.userAccount.userProfile.insightFunctions.filter(item => item.id === 2).length > 0,
          hasFinancialsAccess: _response.userAccount.userProfile.insightFunctions.filter(item => item.id === 3).length > 0,
        };
        this.remittanceRelationIds = _response.merchantHierarchy.remittanceRelationIds;
        // this.remittanceRelationIds = [
        //   'R0347400', 'R0320500', 'R0495000', 'R0721600', 'R0572100', 'R0451100'
        // ];
        this.api.isFeatureEnabled = featureFlag(this.api.config, this.userDetails);
        resolve(response);
      });
    });
  }

  getUserPref() {
    return new Promise((resolve, reject) => {
      this.keycloakService.loadUserProfile().then((user) => {
        this.userDetails = user;
        this.http.get(`${this.api.appConfig.baseUrl}${AppConstants.getApi('USER_CONFIG')}/${this.userDetails.username}`).toPromise()
          .then((res) => {
            const results = [
              {
                type: 'filters',
                value: []
              },
              {
                type: 'layout',
                value: []
              }
            ];
            this.preferences = (res || results);
            resolve(this.preferences);
          }).catch((error) => {
            console.error('Error while fetching user preferences');
            reject(error);
          });
      }).catch((error) => {
        console.error('Keycloak user profile error');
        reject(error);
      });
    });
  }

  set preferences(userPref) {
    this._userPreferences = userPref;
  }

  get preferences() {
    return this._userPreferences;
  }
}
