import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';

import { ComboBoxComponent } from '../../component/combo-box/combo-box.component';
import { ClickAwayDirective } from '../../directives/click-away.directive';
import { DuelPicklistComponent } from '../../component/duel-picklist/duel-picklist.component';
import { PicklistComponent } from '../../component/picklist/picklist.component';
import { DropdownFilterComponent } from '../../component/dropdown-filter/dropdown-filter.component';
import { SearchPicklistPipe } from '../../pipe/search-picklist.pipe';
import { I18nDirective } from '../../directives/i18n.directive';
import { SetFocusDirective } from '../../directives/set-focus.directive';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { SortbyPipe } from '../../pipe/sortby.pipe';
import { CarouselComponent } from '../../component/carousel/carousel.component';
import { InsightDatatableComponent } from '../../component/insight-datatable/insight-datatable.component';
import { SortbyTranslatePipe } from 'src/app/pipe/sortby-translate.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
  declarations: [
    ComboBoxComponent,
    ClickAwayDirective,
    DuelPicklistComponent,
    PicklistComponent,
    SearchPicklistPipe,
    I18nDirective,
    SetFocusDirective,
    TranslatePipe,
    SortbyPipe,
    CarouselComponent,
    InsightDatatableComponent,
    SortbyTranslatePipe,
    DropdownFilterComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  exports: [
    ComboBoxComponent,
    ClickAwayDirective,
    DuelPicklistComponent,
    PicklistComponent,
    SearchPicklistPipe,
    I18nDirective,
    SetFocusDirective,
    TranslatePipe,
    SortbyPipe,
    CarouselComponent,
    InsightDatatableComponent,
    SortbyTranslatePipe,
    DropdownFilterComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ]
})
export class SharedModule { }
