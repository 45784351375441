import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../service/translate.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(public translateApi: TranslateService) {  }

  transform(key: any, args?: any): any {
    return (this.translateApi.data && this.translateApi.data.hasOwnProperty(key)) ? this.translateApi.data[key] : `Cqnc:${key}`;
  }

}
