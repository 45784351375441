import { Component, OnInit, OnDestroy } from '@angular/core';
import {FilterService} from '../../service/filter.service';
import { AppService } from '../../service/app.service';
import { AppConstants } from '../../constants/app-constants.constants';
import { isEmpty, map, get, find, findIndex, forEach, cloneDeep, concat } from 'lodash';
import { Router } from '@angular/router';
import { GqlQueryBuilderService } from '../../service/gqlQueryBuilder.service';
import moment from 'moment';
import { UserService } from '../../service/user.service';
import { ToastService } from '../../service/toast.service';
import { TranslateService } from '../../service/translate.service';
import { formatDateAndTime } from '../../utils/utility';
import { trigger, state, style, transition, animate, keyframes, query, animateChild, group } from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { map as rxjs_map } from 'rxjs/operators';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('searchAnimation', [
      transition('* <=> *', [
        group([
          query('@focusNotfocus', [
            animateChild()
          ], { optional: true }),
          query('@searchButtonAnimate', [
            animateChild()
          ], { optional: true }),
          query('@slideInOut', [
            animateChild()
          ], { optional: true })
        ])
      ])
    ]),
    trigger('focusNotfocus', [
      state('focus', style({
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '0px'
      })),
      state('not-focus', style({
        borderTopLeftRadius: '2em',
        borderBottomLeftRadius: '2em',
      })),
      transition('focus => not-focus', [
        animate('0.3s', keyframes([
          style({borderTopLeftRadius: '4px', borderBottomLeftRadius: '0', offset: 0}),
          style({borderTopLeftRadius: '1em', borderBottomLeftRadius: '1em', offset: 0.5}),
          style({borderTopLeftRadius: '2em', borderBottomLeftRadius: '2em', offset: 1}),
        ]))
      ]),
      transition('not-focus => focus', [
        animate('0.3s', keyframes([
          style({borderTopLeftRadius: '2em', borderBottomLeftRadius: '2em', offset: 0}),
          style({borderTopLeftRadius: '1em', borderBottomLeftRadius: '1em', offset: 0.5}),
          style({borderTopLeftRadius: '4px', borderBottomLeftRadius: '0', offset: 1}),
        ]))
      ]),
    ]),
    trigger('searchButtonAnimate', [
      state('focus', style({
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '0px'
      })),
      state('not-focus', style({
        borderTopRightRadius: '2em',
        borderBottomRightRadius: '2em',
      })),
      transition('focus => not-focus', [
        animate('0.3s', keyframes([
          style({borderTopRightRadius: '4px', borderBottomRightRadius: '0', offset: 0}),
          style({borderTopRightRadius: '1em', borderBottomRightRadius: '1em', offset: 0.9}),
          style({borderTopRightRadius: '2em', borderBottomRightRadius: '2em', offset: 1}),
        ]))
      ]),
      transition('not-focus => focus', [
        animate('0.3s', keyframes([
          style({borderTopRightRadius: '2em', borderBottomRightRadius: '2em', offset: 0}),
          style({borderTopRightRadius: '1em', borderBottomRightRadius: '1em', offset: 0.1}),
          style({borderTopRightRadius: '4px', borderBottomRightRadius: '0', offset: 1}),
        ]))
      ]),
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        animate('0.3s linear', keyframes([
          style({ height: '0', overflow: 'hidden', opacity: 0, offset: 0, }),
          style({ height: '*', overflow: 'hidden', opacity: 0.9, offset: 0.99 }),
          style({ height: '*', overflow: 'initial', opacity: 1, offset: 1 })
        ]))
      ]),
      transition(':leave', [
        /* style({ height: '*', opacity: 1, overflow: 'hidden' }), */
        /* animate('0.3s ease-in', style({ height: '0', opacity: 0, overflow: 'hidden' })), */
        animate('0.3s linear', keyframes([
          style({ height: '*', opacity: 1, overflow: 'hidden', offset: 0 }),
          style({ height: '0', opacity: 0, overflow: 'hidden', offset: 1 })
        ]))
      ])
    ])
  ]
})
export class SearchComponent implements OnInit, OnDestroy {
  showList = false;
  showDefault = false;
  quickSearchOptions;
  defaultQuickSearch;
  currentQuickSearch;
  searchList: Array<any> = []; // root collection of the component
  previousStateSearchList: Array<any> = [];
  availableFilters: Array<any> = [];
  selectedFilters: Array<any> = [];
  searchCriteria: string;

  savedFilters: Array<any> = [];
  selectedSavedFilter = {
    name: '',
    default: false,
    value: []
  };
  previousSelectedSavedFilter = {
    name: '',
    default: false,
    value: []
  };
  newFilterName: string;
  userPrefSubscription: any;
  isSavedFiltersModified = new Subject<boolean>();
  isSavedFiltersModified$ = this.isSavedFiltersModified.asObservable();


  constructor(public fs: FilterService, public appService: AppService, public router: Router,
              public gql: GqlQueryBuilderService, public userApi: UserService, public translate: TranslateService,
              public ts: ToastService) {
    
  }

  ngOnInit() {
    this.availableFilters = (this.userApi.userRights.canSeeUserDetails) ? 
                            concat(AppConstants.filterMap, AppConstants.consumerBasedFilterMap) : AppConstants.filterMap;

    this.quickSearchOptions = this.availableFilters
    .filter(item => item.quickSearch)
    .map(item => ({name: item.name, description: this.translate.translateKey(item.description), tip: this.translate.translateKey(item.tip), quickSearchOrder: item.quickSearchOrder }))
    .sort((a, b) => a.quickSearchOrder - b.quickSearchOrder);

    this.defaultQuickSearch = localStorage.getItem('defaultQuickSearch') ? JSON.parse(localStorage.getItem('defaultQuickSearch')) : this.quickSearchOptions[0];
    this.currentQuickSearch = this.defaultQuickSearch;
    
    const preference = find(this.userApi.preferences, (pref) => {
      return pref.type === 'filters';
    });
    this.savedFilters = (preference && preference.value) || [];

    this.isSavedFiltersModified$.subscribe(r => {
      if (!r) {
        this.selectedSavedFilter = {
          name: '',
          default: false,
          value: []
        };
        this.newFilterName = '';
      }
    });
  }

  ngOnDestroy() {

  }

  toggleSearchFilter() {
    this.showList = !this.showList;
    this.showDefault = false;
  }

  toggleDefaultSearch() {
    if(this.showDefault) return false;
    else 
      this.showDefault = true;
      this.showList = false;
      this.currentQuickSearch = this.defaultQuickSearch;
  }

  isSavedQuery() {
    return !!(this.selectedSavedFilter && this.selectedSavedFilter.name);
  }

  isQueryValid() {
    let isValid = true;
    if (this.searchList && this.searchList.length === 0) { isValid = false;  return isValid; }
    this.searchList.forEach( (filterItem) => {
      if (isEmpty(filterItem.selectedFilter)) {
        isValid = false;
      } else if (filterItem.selectedFilterItems && filterItem.selectedFilterItems.length === 0) {
        isValid = false;
      } else if (!filterItem.selectedFilterItems[0].name
        || (filterItem.selectedFilterItems[1] && !filterItem.selectedFilterItems[1].name)) {
        isValid = false;
      }
    });
    return isValid;
  }

  stringifyFilterItems(filterItem) {
    return map(filterItem, (item) => {
      return (item.displayName instanceof Date) ? formatDateAndTime(item.displayName) : item.displayName;
    }).join(', ');
  }

  quickSearch(event) {
    event.stopPropagation();
    if (this.searchList.length) { return ; }
    this.searchList = [];
    const quickFilter = this.availableFilters.find( (filter) => {
      return filter.name === this.currentQuickSearch.name;
    });

    const newFilter = {
      selectedFilter: {},
      filterList: JSON.parse(JSON.stringify(this.availableFilters)),
      availableFilterItems: [],
      selectedFilterItems: []
    };

    newFilter.selectedFilter = quickFilter;
    newFilter.selectedFilterItems.push({name: this.searchCriteria, displayName: this.searchCriteria});
    this.selectedFilters = [quickFilter];
    this.searchList.push(newFilter);
    this.showDefault = false;
    this.searchPayments();
  }

  changeDefaultQuickSearh(type) {
    const defaultQuickSearch = this.quickSearchOptions.filter(item => item.name === type)[0];
    localStorage.setItem('defaultQuickSearch', JSON.stringify(defaultQuickSearch));
    this.currentQuickSearch = defaultQuickSearch;
    this.defaultQuickSearch = defaultQuickSearch;
    this.clearFilter()
  }

  changeQuickSearh(type) {
    const currentQuickSearch = this.quickSearchOptions.filter(item => item.name === type)[0];
    this.currentQuickSearch = currentQuickSearch;
    this.clearFilter()
  }

  getQuickSearchLabel(type) {
    return this.quickSearchOptions.filter(item => item.name)[0].description;
  }

  keyUpEvent(event) {
    if(!this.searchCriteria.length) this.clearFilter();
    const keyIdx = event.keyCode;
    if (keyIdx === 13) {
      this.quickSearch(event);
    }
  }

  searchPayments() {
    if (!this.isQueryValid()) {
      // TODO throw some errors
      return;
    }
    this.searchCriteria = '';
    this.searchList.forEach( (item) => {
      this.searchCriteria +=
        `${this.translate.translateKey(item.selectedFilter.description)} : ${this.stringifyFilterItems(item.selectedFilterItems)}; `;
    });
    this.previousStateSearchList = JSON.parse(JSON.stringify(this.searchList));
    this.previousSelectedSavedFilter = JSON.parse(JSON.stringify(this.selectedSavedFilter));
    this.gql.setSelectedFilters(this.searchList);
    this.showList = false;
    if (!this.isSavedQuery()) this.newFilterName = '';
    this.router.navigateByUrl('/payments');
  }

  cancelSearch() {
    this.searchList = JSON.parse(JSON.stringify(this.previousStateSearchList));
    this.selectedSavedFilter = JSON.parse(JSON.stringify(this.previousSelectedSavedFilter));
    this.newFilterName = this.selectedSavedFilter.name;
    // TODO reset selected filters & load query
    this.selectedFilters = [];
    forEach(this.previousStateSearchList, (el) => {
      this.selectedFilters.push(el.selectedFilter);
    });
    if (!this.isSavedQuery()) this.newFilterName = '';
    this.showList = false;
    // this.toggleSearchFilter();
  }

  isInvalid() {
    return !this.isQueryValid();
  }

  removeFilter(item) {
    this.searchList.splice(item.index, 1);
    const i = this.selectedFilters.indexOf(item.filter.selectedFilter);
    this.selectedFilters.splice(i, 1);
    this.selectedFilters = [...this.selectedFilters];
    this.isSavedFiltersModified.next(false);
  }

  modifySavedFilter(value) {
    if(value.isModified) {
      this.isSavedFiltersModified.next(false);
    }
  }

  getValues(filterRow): Observable<any> {
    const query = this.gql.getValue('getValues', filterRow.selectedFilter.name);
    return this.appService.getValues('/graphql', query, filterRow.selectedFilter.name)
      .pipe(rxjs_map((result) => {
        const values = get(result, 'data.getValues');
        filterRow.availableFilterItems = values.map((item) => {
          return ({name: item.id, displayName: item.name});
        });
      }));
  }

  onSelectFilter(event, filter) {
    event.stopPropagation();
    
    if (this.searchList.length >= this.availableFilters.length) { return; }
    const items = [];
    const newFilter = {
      selectedFilter: filter,
      filterList: JSON.parse(JSON.stringify(this.availableFilters)),
      availableFilterItems: [],
      selectedFilterItems: []
    };


    if (['TEXT', 'NUMERIC'].includes(filter.type)) {
      items.push({name: null, displayName: null});
    } else if (['DATE', 'TIME'].includes(filter.type)) {
      items.push({name: null, displayName: null});
      items.push({name: null, displayName: null});
    } else if (['LIST'].includes(filter.type)) {
      // Invoke all the APIs here for the LIST type filters
      if(newFilter.selectedFilter.name === 'statusCode') {
        this.getValues(newFilter).subscribe((r) => {
          console.log(newFilter);
          newFilter.availableFilterItems = newFilter.availableFilterItems.sort((a, b) => {
            if(Number(a.name) < Number(b.name)){
              return -1;
            } else if (Number(a.name) > Number(b.name)){
              return 1;
            } else {
              return 0;
            }
          });
        });
      } else if (newFilter.selectedFilter.name === 'merchantId') {
        this.appService.getMerchantIds().subscribe((result: any) => {
          newFilter.availableFilterItems = result.sort((a,b)=> a.id - b.id).map(item => ({name: item.id, displayName: `${item.id} - ${item.name}`}));
        }, error => {
          this.ts.failure(this.translate.translateError(error));
        });
      } else {
        this.getValues(newFilter).subscribe((r) => {});
      }
    }
    newFilter.selectedFilterItems = items;

    this.searchList.push(newFilter);
    this.selectedFilters = [...this.selectedFilters, filter];
    this.isSavedFiltersModified.next(false);
  }

  saveFilterPref() {
    // TODO check if filter already loaded to be saved or create new filter
    if (this.selectedSavedFilter.name) {
      const savedFilter = find(this.savedFilters, (filter) => {
        return filter.name === this.selectedSavedFilter.name;
      });
      savedFilter.value = this.searchList;
    } else {
      if (this.savedFilters.length >= 10) {
        this.ts.failure('Cannot save more than 10 queries');
        return;
      }
      this.savedFilters.push({name: this.newFilterName, default: false, value: this.searchList});
    }
    // this.userApi.userPreferences['filters'].push({name: 'filter-1', default: false, value: this.searchList});
    this.appService.saveFilterPref(this.savedFilters, this.userApi.userDetails).subscribe((response) => {
      this.ts.success('Query saved successfully.');
      if (this.newFilterName) {
        this.selectedSavedFilter = find(this.savedFilters, f => f.name === this.newFilterName);
      }
    }, (error) => {
      this.ts.failure(this.translate.translateError(error));
      console.error(error);
    });
  }

  loadSavedFilter(filter) {
    this.selectedSavedFilter = cloneDeep(filter);
    this.searchList = cloneDeep(filter.value);
    this.newFilterName = filter.name;

    // selected filters
    this.selectedFilters = [];
    forEach(filter.value, (f) => {
      const temp = find(this.availableFilters, (av) => {
        return f.selectedFilter.name === av.name;
      });
      if (temp) {
        this.selectedFilters.push(temp);
      }
    });
  }

  clearFilter() {
    this.selectedSavedFilter = {
      name: '',
      default: false,
      value: []
    };
    this.searchList = [];
    this.selectedFilters = [];
    this.newFilterName = '';
    this.searchCriteria = '';
    this.gql.setSelectedFilters(this.searchList);
    this.previousStateSearchList = JSON.parse(JSON.stringify(this.searchList));
    this.previousSelectedSavedFilter = JSON.parse(JSON.stringify(this.selectedSavedFilter));
  }

  deleteFilter(event, item) {
    event.stopPropagation();
    const idx = findIndex(this.savedFilters, (filter) => {
      return filter.name === item.name;
    });
    const clearFilter = this.selectedSavedFilter.name === item.name;
    if (idx < 0) { return; }
    const deletedFilter = this.savedFilters.splice(idx, 1);
    this.appService.saveFilterPref(this.savedFilters, this.userApi.userDetails).subscribe((response) => {
      this.ts.success('Query deleted successfully');
      if (clearFilter) {
        this.clearFilter();
      }
    }, (error) => {
      this.ts.failure(this.translate.translateError(error));
      console.error(error);
      this.savedFilters.splice(idx, 0, ...deletedFilter);
    });
  }

  isSaveNotAllowed() {
    return !((this.selectedSavedFilter.name || this.newFilterName) && this.isQueryValid());
  }

  openSearchBox(event) {
    event.stopPropagation();
    if (this.showList) {
      return ;
    }
    this.toggleSearchFilter();
  }

  clearText(event) {
    event.stopPropagation();
    this.searchCriteria = '';
    this.clearFilter();
  }

  /* getAdditionalClass() {
    return this.showList ? 'focus' : 'not-focus';
  } */
}
