import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { get, map } from 'lodash';
import { AppService } from '../../service/app.service';
import { GqlQueryBuilderService } from '../../service/gqlQueryBuilder.service';
import { TranslateService } from '../../service/translate.service';

@Component({
  selector: 'app-filter-row',
  templateUrl: './filter-row.component.html',
  styleUrls: ['./filter-row.component.scss']
})
export class FilterRowComponent implements OnInit {
  @Input() availableFilters: Array<any>;
  @Input() filterRow;
  @Input() selectedFilters: Array<any>;
  @Input() index: number;
  @Output() remove = new EventEmitter();
  @Output() isModified = new EventEmitter();

  showItems = false;
  text = {name: '', displayName: ''};
  number = {name: null, displayName: null};
  dateRange: any;
  dropdown = {name: null, displayName: null};
  constructor(
    public appService: AppService,
    public gql: GqlQueryBuilderService,
    public translate: TranslateService) { }

  ngOnInit() {
    if (!this.filterRow.selectedFilter) { return ; }
    if (this.filterRow.selectedFilter.type === 'NUMERIC') {
      this.number = this.filterRow.selectedFilterItems[0];
    } else if (this.filterRow.selectedFilter.type === 'TEXT') {
      this.text = this.filterRow.selectedFilterItems[0];
    } else if (['DATE', 'TIME'].includes(this.filterRow.selectedFilter.type)) {
      this.dateRange = map(this.filterRow.selectedFilterItems, (item) => item.displayName);
    } else if ('LIST' === this.filterRow.selectedFilter.type && 0 === this.filterRow.availableFilterItems.length) {
      /* const query = this.gql.getValue('getValues', this.filterRow.selectedFilter.name);
      this.appService.getValues('/graphql', query, this.filterRow.selectedFilter.name)
        .subscribe((result) => {
          const values = get(result, 'data.getValues');
          this.filterRow.availableFilterItems = values.map((item) => {
            return ({name: item.id, displayName: item.name});
          });
        }, error => {
          console.error(error);
      }); */

    } else if ('DROPDOWN' === this.filterRow.selectedFilter.type) {
      const values = this.getStaticValues();
      if (values.length > 0) {
        this.filterRow.availableFilterItems = values;
      }
    }
  }

  getStaticValues() {
    let values = [];
    values = this.filterRow.selectedFilter.values;
    if (values && values.length > 0) {
      values = values.map((k) => {
        return {
          name: this.translate.translateKey(k),
          displayName: this.translate.translateKey(k)
        };
      });
    } else {
      values = [];
    }
    return values;
  }

  onSelectDropdownItem(item) {
    this.dropdown = item;
    this.filterRow.selectedFilterItems = [];
    this.filterRow.selectedFilterItems.push(item);
    this.isModified.emit({isModified: true});
  }

  onChangeMultiselectValues() {
    this.isModified.emit({isModified: true});
  }

  padZero(value: number, len: number = 2) {
    return (value + (Math.pow(10, len)) + '').slice(1);
  }

  format(date: Date) {
    if (!date) { return ''; }
    return '' + date.getFullYear() + this.padZero((date.getMonth() + 1)) +
    this.padZero(date.getDate()) + this.padZero(date.getHours()) + this.padZero(date.getMinutes()) + this.padZero(date.getSeconds());
  }

  onChangeDate(event, flag) {
    if (!this.dateRange) { return; }
    this.filterRow.selectedFilterItems[0] = {
      name: this.format(this.dateRange[0]),
      displayName: this.dateRange[0]
    };
    this.filterRow.selectedFilterItems[1] = {
      name: this.format(this.dateRange[1]),
      displayName: this.dateRange[1]
    };
    this.isModified.emit({isModified: true});
  }

  onQuickDatePick(type) {
    switch (type) {
      case '24hours':
        this.filterRow.selectedFilterItems = [
          {
            name: this.format(moment.utc().subtract(24,"hours").toDate()),
            displayName: moment.utc().subtract(24,"hours").toDate()
          },
          {
            name: this.format(moment.utc().toDate()),
            displayName: moment.utc().toDate()
          }    
        ]
      break;
      case 'yesterday':
        this.filterRow.selectedFilterItems = [
          {
            name: this.format(moment().subtract(1,"days").startOf('day').toDate()),
            displayName: moment().subtract(1,"days").startOf('day').toDate()
          },
          {
            name: this.format(moment().subtract(1,"days").endOf('day').toDate()),
            displayName: moment().subtract(1,"days").endOf('day').toDate()
          }    
        ]
      break;      
      case 'week':
        this.filterRow.selectedFilterItems = [
          {
            name: this.format(moment().subtract(1,"week").startOf('week').startOf('day').toDate()),
            displayName: moment().subtract(1,"week").startOf('week').startOf('day').toDate()
          },
          {
            name: this.format(moment().subtract(1,"week").endOf('week').endOf('day').toDate()),
            displayName: moment().subtract(1,"week").endOf('week').endOf('day').toDate()
          }    
        ]
      break;
      case 'month':
        this.filterRow.selectedFilterItems = [
          {
            name: this.format(moment().subtract(1,"months").startOf('month').startOf('day').toDate()),
            displayName: moment().subtract(1,"months").startOf('month').startOf('day').toDate()
          },
          {
            name: this.format(moment().subtract(1,"months").endOf('month').endOf('day').toDate()),
            displayName: moment().subtract(1,"months").endOf('month').endOf('day').toDate()
          }    
        ]
      break;
      case '30days':
        this.filterRow.selectedFilterItems = [
          {
            name: this.format(moment().subtract(30,"days").startOf('day').toDate()),
            displayName: moment().subtract(30,"days").startOf('day').toDate()
          },
          {
            name: this.format(moment().toDate()),
            displayName: moment().toDate()
          }    
        ]
      break;      
      case '90days':
        this.filterRow.selectedFilterItems = [
          {
            name: this.format(moment().subtract(90,"days").startOf('day').toDate()),
            displayName: moment().subtract(90,"days").startOf('day').toDate()
          },
          {
            name: this.format(moment().toDate()),
            displayName: moment().toDate()
          }    
        ]
      break;      
      default:
        break;
    }
    this.dateRange = this.dateRange = map(this.filterRow.selectedFilterItems, (item) => item.displayName);
    this.isModified.emit({isModified: true});
  }

  onChangeInput(filterRow) {
    if ('NUMERIC' === filterRow.selectedFilter.type) {
      this.number.name = this.number.displayName;
      this.filterRow.selectedFilterItems[0] = this.number;
    } else if ('TEXT' === filterRow.selectedFilter.type) {
      this.text.name = this.text.displayName;
      this.filterRow.selectedFilterItems[0] = this.text;
    }
    this.isModified.emit({isModified: true});
  }

  removeFilter(event, filter, index) {
    event.stopPropagation();
    this.remove.emit({filter: filter, index: index});
  }

}
