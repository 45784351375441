import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../service/translate.service';

@Pipe({
  name: 'sortbyTranslate'
})
export class SortbyTranslatePipe implements PipeTransform {

  constructor(public translateApi: TranslateService) {
    
  }

  transform(list: Array<any>, prop?: string): any[] {
    if (!Array.isArray(list)) {
      return;
    }
    list.sort((a: any, b: any) => {
      if (this.translateApi.translateKey(a[prop]) < this.translateApi.translateKey(b[prop])) {
        return -1;
      } else if (this.translateApi.translateKey(a[prop]) > this.translateApi.translateKey(b[prop])) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }

}
