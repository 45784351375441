import { Injectable } from '@angular/core';
import Query from 'graphql-query-builder';
import { pick, forEach, map, keys, reduce, set, each, unset, get } from 'lodash';
import { AppConstants } from '../constants/app-constants.constants';
import { AppService } from '../service/app.service';
import { ToastService } from '../service/toast.service';
import { TranslateService } from '../service/translate.service';

@Injectable({
  providedIn: 'root'
})
export class GqlQueryBuilderService {
  selectedFilters: any;

  constructor(public api: AppService, public ts: ToastService, public translate: TranslateService) { }
  /**
   * format the record set information for the graphql-query-builder
   * @param recordsetInfo page information like limit, token and others passed as object
   */
  buildRecordInfo(recordsetInfo) {
    const { limit, noOfTokensAfter, noOfTokensBefore, token } = recordsetInfo;
    const recordSet = {
      size: limit,
      noOfTokensBefore: noOfTokensBefore,
      noOfTokensAfter: noOfTokensAfter,
      sort: '$sort' // TODO post processing
    };
    if (token) {
      recordSet['token'] = token;
    }
    return recordSet;
  }

  /**
   * format filter object
   * TODO more filters to add
   */
  buildFilters() {
    const filterObject = {};
    forEach(this.selectedFilters, (filter) => {
      if (['DROPDOWN', 'LIST', 'TEXT', 'NUMERIC'].includes(filter.selectedFilter.type)) {
        const obj = {};
        const optionType = filter.selectedFilter.options[0];
        // for startswith pass single value
        obj[optionType] = ['startsWith', 'contains'].includes(optionType) ?
          map(filter.selectedFilterItems, 'name')[0] : map(filter.selectedFilterItems, 'name');
        filterObject[filter.selectedFilter.name] = obj;
      } else if (['DATE', 'TIME'].includes(filter.selectedFilter.type)) {
        filterObject[filter.selectedFilter.name] = {lte: filter.selectedFilterItems[1].name, gte: filter.selectedFilterItems[0].name};
      }
    });
    return filterObject;
  }

  /**
   * format the columns that need to display in the screen
   * @param responseColumns defaults to constants file.
   */
  buildColumns(responseColumns, moea = true) {
    const defaultCol = moea ? {moea: true} : {};
    const payments = reduce(responseColumns, (result, column) => {
      if (!column.checkboxable) {
        set(result, column.prop, true);
      }
      return result;
    }, defaultCol);

    return {
      payments: payments, // TODO remove hardcode payment so as to use generically in other grids
      nextToken: true,
      previousToken: true,
      extendedPageInfo: {
        firstPageToken: true,
        lastPageToken: true,
        previousPageTokens: true,
        nextPageTokens: true,
        countEstimate: true
      }
    };
  }

  parseColumnObject(columns) {
    const key = keys(columns);
    const columnList = [];
    forEach(key, (col) => {
      if ('object' === typeof columns[col]) {
        const colStr = this.parseColumnObject(columns[col]);
        columnList.push(`${col} ${colStr}`);
      } else {
        columnList.push(`${col}`);
      }
    });
    return `{ ${columnList.join(', ')} }`;
  }

  /**
   * Replace $sort and $columns from the query with the actual query string
   * @param query string generated from graph query builder
   * @param columns columns that need to be displayed
   * @param recordsetInfo to build sort string
   */
  postProcessing(query: string, columns: any, recordsetInfo) {
    const column = this.parseColumnObject(columns);
    const sort = `{ field: ${recordsetInfo.sortByColumn}, direction: ${recordsetInfo.sortDirection} }`;
    query = query.replace('\"$sort\"', sort);
    query = query.replace('{ $column }', column);
    return query;
  }

  parseColumnsForCSV(columns: any) {
    if ( columns.length === 0 ) { return ''; }
    const parse = (arr) => {
      if (arr.length === 0) { return ''; }
      if (arr.length === 1) { return arr[0]; }
      return `${arr.pop()} { ${parse(arr)} }`;
    };
    let resultString = '';

    for (let i = 0; i < columns.length; i++) {
      const col = columns[i];
      if (!col.prop) {
        continue;
      }
      resultString = resultString + parse(col.prop.split('.').reverse()) + ', ';
    }
    return resultString;
  }

  /**
   * Export Search result into .csv file
  */
  exportCSV(pageInfo, responseColumns) {
    const query = 'listPayments';
    const obj = JSON.parse(JSON.stringify(pageInfo));
    obj['limit'] = 1000;
    obj['token'] = 'TOKEN';
    const q = this.buildQuery(query, obj, responseColumns, false);
    const col = this.parseColumnsForCSV(responseColumns);

    const csv = {
      query: q,
      selectionSet: col
    };
    console.log(csv);
    this.ts.info(this.translate.translateKey('fileDownloadInformationMessage'));
    this.api.exportCSV(csv).subscribe((response: any) => {
      this.ts.success(this.translate.translateKey('fileDownloadSuccessMessage'));
      const blob = new Blob([response]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `InsightSearchResult_${Date.now()}.csv`;
      link.click();
    }, error => {
      console.log(error);
      this.ts.failure(this.translate.translateError(error));
    });
  }


  buildQuery(query, pageInfo, responseColumns, moea = true) {
    const recordsetInfo = pick(pageInfo, ['limit', 'noOfTokensAfter', 'noOfTokensBefore', 'token', 'sortDirection', 'sortByColumn']);
    const alias = {
      recordsetInfo: this.buildRecordInfo(recordsetInfo)
    };
    if (this.selectedFilters && this.selectedFilters.length > 0) {
      alias['filter'] = this.buildFilters();
    }

    const gqlQuery = new Query(query, alias);
    const columns = this.buildColumns(responseColumns, moea);
    gqlQuery.find(['$column']);
    const queryString = this.postProcessing(gqlQuery.toString(), columns, recordsetInfo);
    return `{ ${queryString} }`;
  }

  /**
   * parse string to objects to pass to gql query
   * @param columnStr input string in format of object.prop1.prop2
   * return string or an object (if it has multiple levels)
   */
  parseColumnStringToObject(columnStr: string) {
    const arr = columnStr.split('.');
    if (arr.length === 1) {
      return arr[0];
    }
    const tempObj = {};
    const el = arr.splice(0, 1)[0];
    tempObj[el] = [this.parseColumnStringToObject(arr.join('.'))];
    return tempObj;

  }

  /**
   * Builds a graphql query for the Payment Details
   * @param query graphql query name
   * @param paymentId moea
   * @param fields list of fields to be displayed
   */
  buildPaymentQuery(query, paymentId, fields) {
    const gqlQuery = new Query(query);
    gqlQuery.filter({ filter: `${paymentId}`});
    const gqlFields = [];
    // can be refactored to a recursive function
    each(map(fields), (col) => {
      if (col.prop) {
        if (col.children && col.children.length > 0) {
          forEach(col.children, (childColumn) => {
            if (childColumn.prop) {
              gqlFields.push(this.parseColumnStringToObject(childColumn.prop));
            }
          });
        } else {
          gqlFields.push(this.parseColumnStringToObject(col.prop));
        }
      }
    });
    /* each(map(fields, 'prop'), (colString) => {
      if (colString) {
        gqlFields.push(this.parseColumnStringToObject(colString));
      }
    }); */
    gqlQuery.find(gqlFields);
    return `{${gqlQuery}}`;
  }


  /*
   * get all statuses for the payment status filter.
   * TODO may need changes to implement authorization based on login user.
   */
  getStatuses() {
    const query = new Query('listPaymentStatusMappings');
    query.find(['status', 'statusCode']);
    return `{ ${query.toString()} }`;
  }

  getSelectedFilters() {
    return this.selectedFilters;
  }
  setSelectedFilters(filters) {
    this.selectedFilters = filters;
  }

  getValue(query: string, type: string, fields: Array<any> = ['id', 'name']) {
    const q = new Query(query);
    q.filter({ type });
    q.find(fields);
    return `{ ${q.toString()} }`;
  }

  getGraphqlValues(query: string, filters: any, fields: Array<any> = ['id', 'name']) {
    const q = new Query(query);
    const f = filters ? {limit: 200, ...filters} : {limit: 200};
    q.filter(f);
    q.find(fields);
    return `{ ${q.toString()} }`;
  }

  /* ANALYTICS NEW */
  // Authorization Rate
  buildOverallAuthRateQuery(query: string, type: string, fields: Array<any>) {
    const q = new Query(query);
    q.filter({ type });
    q.find(fields);
    return `{ ${q.toString()} }`;
  }

  unsetReportingCurrency(filters) {
    const obj = {reportingCurrencyCode: get(filters, 'reportingCurrencyCode[0]') || 'EUR'};
    unset(filters, 'reportingCurrencyCode');
    obj['filter'] = filters;
    return obj;
  }

  buildAuthorizationRateQuery(query: string, type: string, fields: any, cols? ) {
    const q = new Query(query);
    q.filter(Object.assign({ groupBy: type }, this.unsetReportingCurrency(fields)));
    const columns = cols || ['key', "authorizedAmount", "authorizedTranCount", "notAuthorizedAmount", "notAuthorizedTranCount"];
    q.find(columns);
    return `{ ${q.toString()} }`;
  }

  buildDisputeQuery(query: string, type: string, fields: any) {
    const q = new Query(query);
    q.filter(Object.assign({ groupBy: type }, this.unsetReportingCurrency(fields)));
    const columns = ["key", "description", "inProgressAmount", "inProgressTranCount", "lossAmount", "lossTranCount", "wonAmount", "wonTranCount", "invalidRepresentmentAmount", "invalidRepresentmentTranCount"]
    q.find(columns);
    return `{ ${q.toString()} }`;
  }

  buildRefundQuery(query: string, type: string, fields: any) {
    const q = new Query(query);
    q.filter(Object.assign({ groupBy: type }, this.unsetReportingCurrency(fields)));
    const columns = ['key', 'description', "refundAmount", "refundTranCount", "paidAmount", "paidTranCount"];
    q.find(columns);
    return `{ ${q.toString()} }`;
  }

  buildPaymentStatusQuery(query: string, fields: any) {
    const q = new Query(query);
    q.filter(Object.assign({ }, this.unsetReportingCurrency(fields)));
    const columns = [ 'key', 'inProgressCount','inProgressAmount','notApplicableCount','notApplicableAmount','successfulCount','successfulAmount','unsuccessfulCount','unsuccessfulAmount'];
    q.find(columns);
    return `{ ${q.toString()} }`;
  }

  buildBinQuery(query: string, type: string, fields: any, cols? ) {
    const q = new Query(query);
    q.filter(Object.assign({ groupBy: type }, this.unsetReportingCurrency(fields)));
    const columns = cols || ['key', "refundAmount", "refundTranCount", "paidAmount", "paidTranCount"];
    q.find(columns);
    return `{ ${q.toString()} }`;
  }

  buildBankBinQuery(query: string, fields: any) {
    const q = new Query(query);
    q.filter(Object.assign({ }, this.unsetReportingCurrency(fields)));
    const columns = ['bankName', "authorizedAmount", "authorizedTranCount", "notAuthorizedAmount", "notAuthorizedTranCount", 
          {"bins": ["key", "authorizedAmount", "authorizedTranCount", "notAuthorizedAmount", "notAuthorizedTranCount"]}];
    q.find(columns);
    return `{ ${q.toString()} }`;
  }

  buildChargebackQuery(query: string, type: string, fields: any, cols: Array<any> = []) {
    const q = new Query(query);
    q.filter(Object.assign({ groupBy: type }, this.unsetReportingCurrency(fields)));
    let columns = [];
    if (cols && cols.length > 0) {
      columns = cols;
    } else {
      columns = ['key', "cbAmount", "cbTranCount", "paidTranCount", "paidAmount", "description"];
    }
    q.find(columns);
    return `{ ${q.toString()} }`;
  }

  buildPaymentProductQuery(query: string, type: string, fields: any, cols: Array<any> = []) {
    const q = new Query(query);
    q.filter(Object.assign({ groupBy: type }, this.unsetReportingCurrency(fields)));
    let columns = [];
    if (cols && cols.length > 0) {
      columns = cols;
    } else {
      columns = [
        "key",
        "amountPercentage",
        "tranCountPercentage",
        "description",
      ];
    }
    q.find(columns);
    return `{ ${q.toString()} }`;
  }

  // Performance
  buildConversionRateQuery(query: string, type: string, fields: Array<any>) {
    const q = new Query(query);
    q.filter(Object.assign({ groupBy: type }, this.unsetReportingCurrency(fields)));
    const columns = ['key', "settlementAmount", "settlementCount", "checkoutAmount", "checkoutCount"];
    q.find(columns);
    return `{ ${q.toString()} }`;
  }

  /* ANALYTICS NEW */
}
