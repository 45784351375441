<div class="in-search-container" (appClickAway)="showList = false; showDefault = false" externalsClickAway=".cdk-overlay-container, .owl-dt-calendar-cell-content" [@searchAnimation]="showList">
  <div class="in-search-flex">
    <div class="in-search-flex-left">
      <input 
        type="text" 
        name="in-search" 
        id="in-search" 
        class="in-search-text form-control" 
        [(ngModel)]="searchCriteria" 
        [placeholder]="showDefault && !showList ? 'Search for ' + currentQuickSearch.description + ':' : 'Search'" 
        autocomplete="off"
        appI18n="attribute|placeholder@@searchLabel"
        (click)="toggleDefaultSearch()"
        [@focusNotfocus]="showList || showDefault ? 'focus' : 'not-focus'"
        (keyup)="keyUpEvent($event)"
        >
    </div>
    <div class="in-search-flex-middle-1">
      <div class="in-search-icon" (click)="clearText($event)">
        <a href="javascript:void(0)">
          <!-- <fa name="plus-circle" ></fa> -->
          <div class="i icon-x-mark-2-functional-blue icon-size-xs" *ngIf="searchCriteria"></div>
          <!-- <fa name="times-circle" *ngIf="showList"></fa> -->
        </a>
      </div>
    </div>
    <div class="in-search-flex-middle">
      <div class="in-search-icon" (click)="openSearchBox($event)">
        <a [attr.aria-label]="('openSearchBoxLabel' | translate)" href="javascript:void(0)">
          <!-- <fa name="plus-circle" ></fa> -->
          <div class="icon-arrow-25 icon-size-sm arrow-direction-bottom" *ngIf="!showList"></div>
          <div class="icon-arrow-25 icon-size-sm arrow-direction-bottom opacity-half" *ngIf="showList"></div>
          <!-- <fa name="times-circle" *ngIf="showList"></fa> -->
        </a>
      </div>
    </div>
    <div class="in-search-flex-right" [@searchButtonAnimate]="showList || showDefault ? 'focus' : 'not-focus'">
      <div class="in-search-icon">
          <a [attr.aria-label]="('searchLabel' | translate)" href="javascript:void(0)" (click)="quickSearch($event)">
          <div class="i icon-magnifier-6 icon-size-sm"></div>
        </a>
      </div>
    </div>
  </div>
  <div class="in-search-list shadow-dropdown" *ngIf="showList" [@slideInOut]>
    <div class="in-search-list-header mt-2 mr-2 mb-2 ml-2">
      <div class="h5" appI18n="@@advanceSearchTiTle">Advanced Search</div>
      <div class="d-flex">
        <button type="button" class="btn btn-tertiary btn-sm" (click)="clearFilter()" title="Reset all" appI18n="attribute|title@@resetAllLabel">
          <div class="icon-refresh-4-functional-blue icon-size-sm d-inline-block"></div>
        </button>
        <div class="dropdown" ngbDropdown placement="bottom-right">
          <button class="dropdown-toggle btn btn-secondary btn-icon-and-text" type="button" 
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle [disabled]="savedFilters.length === 0">
              <div class="btn-icon icon-filter-2-functional-blue" *ngIf="selectedSavedFilter && selectedSavedFilter.name"></div>
            {{ (selectedSavedFilter && selectedSavedFilter.name) || ('loadQueryLabel' | translate) }}
          </button>
          <div ngbDropdownMenu class="dropdown-menu scrollable shadow-modal" aria-labelledby="dropdownMenuButton" role="menu">
            <ul class="list">
              <ng-template ngFor let-i [ngForOf]="savedFilters">
                <li class="dropdown-item list-item" (click)="loadSavedFilter(i)">
                  {{i.name}}
                  <div class="icon-x-mark-2 icon-size-xs delete-icon" (click)="deleteFilter($event, i)"></div>
                </li>
              </ng-template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="in-search-list-body">
      <div class="floating-content">
        <ng-template ngFor let-row [ngForOf]="searchList" let-i="index">
          <div class="filter-row">
              <app-filter-row 
                [availableFilters]="availableFilters" 
                [filterRow]="row"
                [selectedFilters]="selectedFilters"
                (remove)="removeFilter($event)"
                [index]="i"
                (isModified)="modifySavedFilter($event)"
              ></app-filter-row>
          </div>
        </ng-template>
      </div>
      <div class="in-search-add-filter">
        <div class="row add-filter-row">
          <div class="col-lg-2 col-md-12">
            <div class="label" appI18n="@@searchFilterLabel">
              Search by adding filters
            </div>
          </div>
          <div class="col-lg-10 col-md-12">
            <div class="dropdown" ngbDropdown>
              <button class="btn dropdown-toggle btn-secondary rounded-100" type="button" 
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle
                  [disabled]="availableFilters.length <= selectedFilters.length"
                  appI18n="@@addFilterLabel">
                Add a Filter
              </button>
              <div ngbDropdownMenu class="dropdown-menu scrollable shadow-modal" aria-labelledby="dropdownMenuButton" role="menu">
                <ul class="list">
                  <ng-template ngFor let-i [ngForOf]="availableFilters | searchFilter: selectedFilters | sortbyTranslate:'description'">
                    <li class="dropdown-item list-item" (click)="onSelectFilter($event, i)">{{i.description | translate}}</li>
                  </ng-template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="in-search-list-footer">
      <div class="action">
        <div>
          <label class="label mr-1" appI18n="@@queryNameLabel">Name your query</label>
          <input 
            type="text" 
            class="form-control mr-1" 
            [(ngModel)]="newFilterName" 
            placeholder="New Filter name" 
            [disabled]="isSavedQuery()"
            appI18n="attribute|placeholder@@newFilterPlaceholder"
            >
          <button type="button" class="btn btn-secondary" (click)="saveFilterPref()" [disabled]="isSaveNotAllowed()" appI18n="@@saveLabel">Save</button>
        </div>
        <div class="search-actions">
          <button type="button" class="btn btn-lg btn-secondary" (click)="cancelSearch()" appI18n="@@cancelLabel">Cancel</button>
          <button type="button" class="btn btn-lg btn-primary ml-1" (click)="searchPayments()" [disabled]="isInvalid()" appI18n="@@searchLabel">Search</button>
        </div>
      </div>
    </div>
  </div>

  <div class="in-search-list shadow-dropdown" *ngIf="showDefault && !showList" [@slideInOut]>
    <div class="in-search-list-header mt-2 mr-2 mb-1 ml-2">
      <div class="h5" appI18n="@@quickSearchTiTle">/Quick Search</div>
    </div>
    <div clas="d-flex flex-column" *ngFor="let item of quickSearchOptions">
      <div
        [ngClass]="{'bg-light': currentQuickSearch.name === item.name, 'd-inline-flex align-items-center mb-1 ml-2 p-1 c-pointer  ' : true}">
        <div [ngClass]="{'icon-pin-selected': defaultQuickSearch.name === item.name,'icon-pin': defaultQuickSearch.name !== item.name,  'i icon-size-sm mr-1' : true}" (click)="changeDefaultQuickSearh(item.name)"></div>
        <div (click)="changeQuickSearh(item.name)">
          <span class="mr-1">{{item.description}}:</span>
          <span class="text-muted">{{item.tip}}</span>
        </div>
        <span class="badge badge-light ml-1" *ngIf="defaultQuickSearch.name === item.name">Default</span>
      </div>
    </div>
  </div>

</div>
