import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../service/toast.service';
import { trigger, transition, query, style, stagger, animate, keyframes } from '@angular/animations';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),
        query(':enter', stagger('300ms', [
          animate('0.15s ease-in', keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 1, offset: 1}),
          ]))
        ]), { optional: true })
      ])
    ])
  ]
})
export class ToastContainerComponent implements OnInit {
  toasts = [];
  constructor(public ts: ToastService) { }

  ngOnInit() {
    this.ts.toastChange.subscribe(() => {
      this.toasts = this.ts.toasts;
    });
  }
}
