<div class="combo-box" (appClickAway)="closeList()" (keydown.esc)="closeList()">
  <div
    role="combobox"
    [attr.aria-label]="name ? name : ('selectLabel' | translate)"
    tabindex="0"
    class="combo-box-button"
    (keydown.space)="toggleShowList()"
    (keydown.enter)="toggleShowList()"
    (click)="toggleShowList()">
    <div>
      <span class="badge badge-light mr-1"
        *ngFor="let selectedItem of selectedList | slice:0:limit">
        {{ selectedItem[displayProp] }}&nbsp;
        <a href="javascript:void(0)" *ngIf="isMultiSelect" (click)="removeSelected($event, selectedItem)">X</a>
      </span>
      <span *ngIf="selectedList.length === 0" class="mr-1 placeholder">
        <span *ngIf="name">{{name}}</span>
        <span *ngIf="!name" appI18n="@@selectLabel">Select</span>
      </span>
      <span *ngIf="selectedList.length > limit" class="mr-1 limit">+{{selectedList.length - limit}}</span>
    </div>
    <div>
      
      <div class="icon-arrow-25 icon-size-xs arrow-direction-bottom"></div>
    </div>
  </div>
  <div *ngIf="showList" class="combo-box-dropdown border">
    <div class="searchContainer" *ngIf="isSerchable">
      <input type="search" 
        class="form-control form-control-sm"
        name="search"
        [(ngModel)]="searchText"
        appI18n="attribute|placeholder@@searchLabel"
        (keyup)="filterResults()"
        appSetFocus
        autocomplete="off"
        >
    </div>
    <ul class="combo-box-dropdown-list" *ngIf="isMultiSelect">
      <li class="combo-box-item" (click)="selectAll($event)" *ngIf="isSelectAll && isMultiSelect && !searchText">
        <div class="custom-control custom-checkbox pe-none">
          <input type="checkbox" class="custom-control-input" id="selectall" [checked]="isSelectAllChecked()">
          <label for="selectall" class="custom-control-label">Select All</label>
        </div>
      </li>
      <li class="combo-box-item" *ngFor="let item of inList| searchPicklist: searchText : displayProp | slice: 0:maxNumberOfItems ? maxNumberOfItems : inList.length" (click)="toggleselectItem($event, item)">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" [id]="item[keyProp]" [checked]="isChecked(item)">
          <label class="custom-control-label" [for]="item[keyProp]">  
            {{ item[displayProp] }}
          </label>
        </div>
      </li>
    </ul>
    <ul class="combo-box-dropdown-list single-list" *ngIf="!isMultiSelect">
      <li class="combo-box-item" *ngFor="let item of inList| searchPicklist: searchText : displayProp | slice: 0:maxNumberOfItems ? maxNumberOfItems : inList.length" (click)="selectSingleItem($event, item)">
        <a href="javascript:void(0)">
          {{ item[displayProp] }}
        </a>
      </li>
    </ul>
  </div>
  
</div>
