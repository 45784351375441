import { KeycloakService } from 'keycloak-angular';
import { AppService } from '../service/app.service';
import { version } from '../../../package.json';
import { UserService } from '../service/user.service';

/**
 * Initialize the keycloak adapter.
 *
 * @param keycloak KeycloakService
 */
export function initializer(keycloak: KeycloakService, appConfig: AppService, userApi: UserService): () => Promise<any> {
    console.log('***** *   *  ***  *****  ***  *   * *****');
    console.log('  *   **  * **      *   *     *   *   *  ');
    console.log('  *   * * *  **     *   *     *****   *  ');
    console.log('  *   *  **    **   *   *  ** *   *   *  ');
    console.log('***** *   *  ***  *****  ***  *   *   *  ');
    console.log('version: ' + version);
    return (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            let keycloakConfig: any;
            appConfig.loadConfig().then((config: any) => {
                keycloakConfig = {
                    url: config.keycloakURL,
                    clientId: config.keycloakClientId,
                    realm: config.keycloakRealm
                };
                keycloak.init({
                    config: keycloakConfig,
                    initOptions: {
                        onLoad: 'login-required',
                        checkLoginIframe: false
                    }
                }).then(async (authenticated) => {
                    if (!authenticated) { reject('User not authenticated'); }
                    let username;
                    await keycloak.getKeycloakInstance().loadUserProfile().then((r) => {
                        username = r.username;
                    });
                    
                    await userApi.getUserPref();
                    await userApi.loadUserInfo(username);
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                console.error('Error loading config details');
            });
        });
    };
}
