import { Pipe, PipeTransform } from '@angular/core';
import { ArgumentOutOfRangeError } from 'rxjs';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  transform(value: Array<any>, args: Array<any>): any {
    return value.filter( (filter) => {
      const list = args.map(e => e.name );
      return list.indexOf(filter.name) === -1;
    });
  }

}
