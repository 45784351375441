import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSetFocus]'
})
export class SetFocusDirective {
  constructor(el: ElementRef) {
      setTimeout(() => el.nativeElement.focus(), 0);
   }
}
