import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from './service/user.service';
import { AppService } from './service/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Insight';

  constructor( public userApi: UserService, public api: AppService, private titleService: Title) {
    this.titleService.setTitle('Insight');
  }

  ngOnInit() {
  }
}
