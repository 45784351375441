import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() toast;
  options = {
    timeOut: 5000,
    isTimeOut: true
  };

  constructor(public ts: ToastService) {

  }
  ngOnInit() {
    this.activateToast();
  }

  ngOnDestroy() {
    // clearTimeout(this.toast.timeOutId);
  }

  remove() {
    this.toast.isActive = false;
    this.ts.removeToast(this.toast);
  }

  activateToast() {
    this.toast.isActive = true;
    if (this.options.isTimeOut) {
      this.toast.timeOutId = setTimeout(() => this.remove(), this.options.timeOut);
    }
  }

  getIconClass(toast) {
    const obj = {};
    obj[this.ts.getToastIcon(toast.type)] = true;
    return obj;
  }

  showToast(toast) {
    return {
      'show': toast.isActive
    };
  }
}
