<div class="filter-row-container row">
  <div class="col-lg-2 col-md-12 label">
    {{ filterRow.selectedFilter.description | translate }}
  </div>
  <div class="col-lg-10 col-md-11 enum">
    <div class="filter-items" *ngIf="filterRow.selectedFilter.type == 'TEXT'">
      <div>
        <input type="text"
          class="form-control"
          [name]="filterRow.selectedFilter.name"
          [(ngModel)]="text.displayName"
          (keyup)="onChangeInput(filterRow)"
          (change)="onChangeInput(filterRow)"
          autocomplete="off">
      </div>
      <div>
        <div class="icon-x-mark-2 icon-size-xs ml-1 c-pointer" (click)="removeFilter($event, filterRow, index)"></div>
      </div>
    </div>
    <div class="filter-items" *ngIf="filterRow.selectedFilter.type == 'NUMERIC'">
      <div>
        <input type="number" 
          class="form-control"
          [name]="filterRow.selectedFilter.name"
          [(ngModel)]="number.displayName"
          (keyup)="onChangeInput(filterRow)"
          (change)="onChangeInput(filterRow)">
      </div>
      <div>
        <div class="icon-x-mark-2 icon-size-xs ml-1 c-pointer" (click)="removeFilter($event, filterRow, index)"></div>
      </div>
    </div>
    <div class="filter-items date" *ngIf="(filterRow.selectedFilter.type == 'DATE') || (filterRow.selectedFilter.type == 'TIME')">
      <div>
        <div>
          <input class="form-control" 
            [selectMode]="'range'"
            [owlDateTimeTrigger]="dtfrom"
            [owlDateTime]="dtfrom"
            (blur)="onChangeDate($event)"
            placeholder="Date"
            [(ngModel)]="dateRange">
          <owl-date-time #dtfrom></owl-date-time>
          <div>
            <button type="button" class="btn btn-sm btn-link pl-0" (click)="onQuickDatePick('24hours')">Last 24 hours</button>
            <button type="button" class="btn btn-sm btn-link" (click)="onQuickDatePick('yesterday')">Yesterday</button>
            <button type="button" class="btn btn-sm btn-link" (click)="onQuickDatePick('week')">Previous week</button>
            <button type="button" class="btn btn-sm btn-link" (click)="onQuickDatePick('month')">Previous month</button>
            <button type="button" class="btn btn-sm btn-link" (click)="onQuickDatePick('30days')">Last 30 days</button>
            <button type="button" class="btn btn-sm btn-link" (click)="onQuickDatePick('90days')">Last 90 days</button>
          </div>
        </div>
      </div>
      <div>
        <div class="icon-x-mark-2 icon-size-xs ml-1 c-pointer" (click)="removeFilter($event, filterRow, index)"></div>
      </div>
    </div>
    <div class="filter-items" *ngIf="filterRow.selectedFilter.type === 'DROPDOWN'">
      <div>
        <div class="dropdown" ngbDropdown>
          <button class="btn dropdown-toggle btn-secondary rounded-100" type="button" 
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            {{ dropdown.name || ('selectLabel' | translate) }}
          </button>
          <div ngbDropdownMenu class="dropdown-menu scrollable shadow-background-dropdown" aria-labelledby="dropdownMenuButton" role="menu">
            <ul class="list">
              <ng-template ngFor let-filterItem [ngForOf]="filterRow.availableFilterItems">
                <li class="dropdown-item list-item" (click)="onSelectDropdownItem(filterItem)">{{filterItem.displayName}}</li>
              </ng-template>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="icon-x-mark-2 icon-size-xs ml-1 c-pointer" (click)="removeFilter($event, filterRow, index)"></div>
      </div>
    </div>
    <div class="filter-items" *ngIf="filterRow.selectedFilter.type == 'LIST'">
      <div>
        <!-- <div class="multiselect-typeahead rounded-sm">
          <ng-template ngFor let-item [ngForOf]="filterRow.selectedFilterItems">
            <span class="badge badge-dark rounded-sm badge-custom">{{item.displayName}}&nbsp;&nbsp;<a (click)="onRemoveItem(item)" href="javascript:void(0)">X</a></span>
          </ng-template>
          <div class="multiselect-item-input">
            <input type="text" class="form-control form-control-sm" (focus)="onFocusInput()" (blur)="onBlurInput()" [(ngModel)]="filterInputModel" autocomplete="off">
            <div class="multiselect-item-list shadow-modal rounded" *ngIf="showItems">
              <ul class="list">
                <ng-template ngFor let-filterItem [ngForOf]="filterRow.availableFilterItems | filterEnum: filterRow.selectedFilterItems: filterInputModel">
                  <li class="pointer list-item" (click)="onSelectItem(filterItem)">{{filterItem.displayName}}</li>
                </ng-template>
              </ul>
            </div>
          </div>
        </div> -->
        <app-combo-box [inList]="filterRow.availableFilterItems"
          [selectedList]="filterRow.selectedFilterItems"
          [limit]="99"
          [isSerchable]="true"
          [isMultiSelect]="true"
          [keyProp]="'name'"
          [name]="('selectLabel' | translate)"
          (onSelect)="onChangeMultiselectValues()"
        ></app-combo-box>
      </div>
      <div>
        <div class="icon-x-mark-2 icon-size-xs ml-1 c-pointer" (click)="removeFilter($event, filterRow, index)"></div>
      </div>
    </div>
    <div class="filter-items" *ngIf="filterRow.selectedFilter && filterRow.selectedFilter.type == ''">
      <div>
        <input type="text" class="form-control" autocomplete="off">
      </div>
      <div>
        <div class="icon-x-mark-2 icon-size-xs ml-1 c-pointer" (click)="removeFilter($event, filterRow, index)"></div>
      </div>
    </div>
  </div>
  <!-- <div class="col-md-1 remove">
    <a href="javascript:void(0)" class="">
      <div class="icon-x-mark-2 icon-size-xs" (click)="removeFilter(filterRow, index)"></div>
    </a>
  </div> -->
</div>
