import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConstants } from '../constants/app-constants.constants';
import { map } from 'lodash';
import { CustomURLEncoder } from '../utils/urlencoder';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppService {
  public appConfig: any;
  // public userRights: any;
  locale: any;
  resizeSubject = new Subject();
  isFeatureEnabled;


  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  set config(config) { this.appConfig = config; }
  get config() { return this.appConfig; }

  /**
   * Load environment configuration details during app initialization
   */
  loadConfig() {
    return new Promise(async (resolve, reject) => {
      this.http.get('/assets/config.json').toPromise().then((response) => {
        this.appConfig = response;
        resolve(response);
      });
    });
  }

  /**
   * Payment overview result
   * @param uri API path
   * @param query Query for payment overview
   */
  getData(uri, query) {
    const body = {
      'query' : query,
      'variables': null,
      'operationName': null
    };

    return this.http.post(`${this.appConfig.baseUrl}${uri}`, JSON.stringify(body), this.httpOptions);
  }

  /**
   * Get all statuses available for filters
   * @param uri path
   * @param query gql query
   */
  getAllStatuses(uri, query) {
    const body = {
      'query' : query,
      'variables': null,
      'operationName': null
    };
    return this.http.post(this.appConfig.baseUrl + uri, JSON.stringify(body)/* , this.httpOptions */);
  }

  /**
   * fetch predefined filter values for search
   * @param uri uri
   * @param query graphql query
   */
  getValues(uri, query, cacheKey?) {
    const body = {
      query,
      'variables': null,
      'operationName': null
    };
    const options = {};
    if(cacheKey) {
      options['params'] = {
        cache: cacheKey
      };
    }
    return this.http.post(this.appConfig.baseUrl + uri, JSON.stringify(body), { ...options });
  }

  getPaymentById(uri, query) {
    const body = {
      'query' : query,
      'variables': null,
      'operationName': null
    };
    return this.http.post(`${this.appConfig.baseUrl}${uri}`, JSON.stringify(body));
  }

  /**
   * get Data for graphql queries related to financial report
   * @param uri 
   * @param query 
   */
  execFinancialQueries(uri, query) {
    const body = {
      'query' : query,
      'variables': null,
      'operationName': null
    };
    return this.http.post(`${this.appConfig.baseUrl}${uri}`, JSON.stringify(body));
  }

  /**
   * Change password for the user
   * @param username username
   * @param oldpwd old password
   * @param newpwd new password
   */
  changePassword(username, oldpwd, newpwd) {
    const body = {
      'password': newpwd,
      'oldPassword': oldpwd
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // TODO to be moved to http interceptor
    return this.http.post(`${this.appConfig.baseUrl}/users/${username}/resetpassword`,
      JSON.stringify(body), {...options, observe: 'response'});
  }

  /**
   * Get user info
   * @param username user to see more info
  */
  getUser(username) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(`${this.appConfig.baseUrl}${AppConstants.getApi('USER')}/${username}`, {...options}) ;
  }

  /**
   * Get list of users
  */
  getAllUsers(pageNumber = 0, pageSize = 10, firstName = null, lastName = null, username = null) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    options['params'] = new HttpParams({ encoder: new CustomURLEncoder()});
    
    options['params'] = options['params'].append('showMerchants', true);
    options['params'] = options['params'].append('showMerchantGroups', true);
    options['params'] = options['params'].append('pageNumber', pageNumber);
    options['params'] = options['params'].append('pageSize', pageSize);
    
    if (firstName) options['params'] = options['params'].append('firstName', firstName.trim());
    if (lastName) options['params'] = options['params'].append('lastName', lastName.trim());
    if (username) options['params'] = options['params'].append('username', username.trim());

    return this.http.get(`${this.appConfig.baseUrl}${AppConstants.getApi('ALL_USERS')}`, {...options}) ;
  }

  /**
   * Create new user
   * @param newUser detail of the new user to create
  */
   createUser(newUser: any) {
    const body = {
      'userAccountInput': {
          'username': newUser.username,
          'password': '_0Test123X9.;;',
          'firstName': newUser.firstName,
          'lastName': newUser.lastName,
          'enabled': true,
          'admin': newUser.admin,
          'ingenicoUser': newUser.ingenicoUser,
          'userProfile': {
              'insightFunctionIds': newUser.insightFunctionIds
          }
      },
      'userToMerchantLink': {
          'merchantIds': newUser.merchantIds,
          'merchantAccountIds': newUser.merchantAccountIds,
          'merchantGroupIds': newUser.merchantGroupIds
      }
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(`${this.appConfig.baseUrl}${AppConstants.getApi('USER')}`, JSON.stringify(body), options);
  }


  /**
   * Update user details
   * @param user details of from current user to edit
  */
  editUser(user: any) {
    const body = {
      'userAccountInput': {
          'firstName': user.firstName,
          'lastName': user.lastName,
          'enabled': true,
          'admin': user.admin,
          'ingenicoUser': user.ingenicoUser,
          'userProfile': {
              'insightFunctionIds': user.insightFunctionIds
          }
      },
      'userToMerchantLink': {
          'merchantIds': user.merchantIds,
          'merchantAccountIds': user.merchantAccountIds,
          'merchantGroupIds': user.merchantGroupIds
      }
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(`${this.appConfig.baseUrl}${AppConstants.getApi('USER')}/${user.username}`,
    JSON.stringify(body), {...options, observe: 'response'});
  }

  /**
   * Fetch list of merchants
   */
  getMerchants(query?, remittanceRelationIds?) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (query) {
      options['params'] = {
        name: query
      };
    }
    if(remittanceRelationIds) {
      options['params'] = {
        remittanceRelationIds
      };
    }
    return this.http.get(`${this.appConfig.baseUrl}${AppConstants.getApi('MERCHANTS')}`, {...options});
  }

  /**
   * Fetch list of merchant(s) accounts
   * @param merchants - list of merchant(s)
   */
  getMerchantAccounts(merchants: Array<any> = [], remittanceRelationIds?) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (merchants.length > 0) {
      options['params'] = {
        'merchantIds': map(merchants, 'id').join(','),
      };
    }
    if(remittanceRelationIds) {
      options['params'] = {
        remittanceRelationIds
      };
    }
    
    return this.http.get(`${this.appConfig.baseUrl}${AppConstants.getApi('MERCHANT_ACCOUNTS')}`, {...options});
  }

  getMerchantGroups(merchantGroupName: string = '', merchants: Array<any> = [], pageNumber: Number = 0, pageSize: Number = 10) {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: {},
    };
    options['params'] = {
      showUserAccounts: true,
      showMerchantAccounts: true,
      pageNumber,
      pageSize,
    };

    if (merchants.length > 0) {
      options.params['merchantIds'] = map(merchants, 'id').join(',');
    }
    return this.http.get(`${this.appConfig.baseUrl}${AppConstants.getApi('MERCHANT_GROUPS')}`, {...options});
  }

  getMerchantGroupDetails(merchantGroup: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(`${this.appConfig.baseUrl}${AppConstants.getApi('MERCHANT_GROUP')}/${merchantGroup.merchantGroup.id}`,
    {...options});
  }

  getMerchantIds() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(`${this.appConfig.baseUrl}${AppConstants.getApi('MERCHANT_IDS')}`,
    {...options});
  }

  getAllUsersForGroup() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http.get(`${this.appConfig.baseUrl}${AppConstants.getApi('ALL_USERS_FOR_GROUP')}`, {...options}) ;
  }

  /**
   * save filter as user preference
   * @param pref in the format {filters: ..., layout: ...}
   */
  saveFilterPref(filters, userDetails) {
    const pref = [{
      type: 'filters',
      value: filters
    }];
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(`${this.appConfig.baseUrl}${AppConstants.getApi('USER_CONFIG')}/${userDetails.username}`,
      JSON.stringify(pref), {...options, observe: 'response'});
  }

  savePaymentLayoutPref(columns, userDetails, pageLimit) {
    const pref = [{
      type: 'layout',
      value: columns
    }, {
      type: 'pageLimit',
      value: pageLimit
    }];
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(`${this.appConfig.baseUrl}/userconfiguration/${userDetails.username}`,
      JSON.stringify(pref), {...options, observe: 'response'});
  }

  saveAccountGroup(groupName: string, merchantId, accounts: Array<any>) {
    const body = {
      name: groupName,
      validIndicator: 1,
      merchantId: merchantId,
      merchantAccountIds: map(accounts, 'id')
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(`${this.appConfig.baseUrl}${AppConstants.getApi('MERCHANT_GROUP')}`,
      JSON.stringify(body), {...options, observe: 'response'});
  }

  assignUsersToGroups(group, users: Array<any>) {
    const body = map(users, (user) => {
      return user.id;
    });
    return this.http.post(`${this.appConfig.baseUrl}${AppConstants.getApi('ASSIGN_USERS_TO_GROUP')}/${group.merchantGroup.id}`,
      JSON.stringify(body));
  }

  modifyAccountGroup(groupId, groupName: string, merchantId, accounts: Array<any>) {
    const body = {
      name: groupName,
      validIndicator: 1,
      merchantId: merchantId,
      merchantAccountIds: map(accounts, 'id')
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(`${this.appConfig.baseUrl}${AppConstants.getApi('MERCHANT_GROUP')}/${groupId}`,
      JSON.stringify(body), {...options, observe: 'response'});
  }

  // Delete operations

  deleteGroup(group) {
    return this.http.delete(`${this.appConfig.baseUrl}${AppConstants.getApi('MERCHANT_GROUP')}/${group.merchantGroup.id}`,
      {observe: 'response'});
  }

  deleteUser(user) {
    return this.http.delete(`${this.appConfig.baseUrl}${AppConstants.getApi('USER')}/${user.username}`,
      {observe: 'response'});
  }


  resetPaymentLayoutPref(userDetails, preferenceType: string) {
    const options = {};
    options['params'] = {
      type: preferenceType
    };
    return this.http.delete(`${this.appConfig.baseUrl}/userconfiguration/${userDetails.username}`,
      { ...options, observe: 'response'});

  }

  /**
   * Export Search result into .csv file
  */
 exportCSV(data: any) {
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.http.post(`${this.appConfig.baseUrl}${AppConstants.getApi('EXPORT_CSV')}`,
  JSON.stringify(data), {...options, responseType: 'blob'});
  }

}
