import { Directive, OnInit, OnDestroy, Input, ElementRef, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '../service/translate.service';

@Directive({
  selector: '[appI18n]'
})
export class I18nDirective implements OnInit, OnDestroy {
  @Input() appI18n: string;
  private subscription: Subscription;

  constructor(private el: ElementRef, private renderer: Renderer2, public translateApi: TranslateService) {
    this.subscription = this.translateApi.dataObservable.subscribe((result) => {
      this.translate();
    });
  }

  getTranslatedValue(token: string) {
    const idx = token.indexOf('@@');
    const key = token.substring(idx + 2);
    return (this.translateApi.data.hasOwnProperty(key)) ? this.translateApi.data[key] : `Cqnc:${key}`;
  }

  translate() {
    if (!this.appI18n) {
      return ;
    }
    const value = this.getTranslatedValue(this.appI18n);
    let type = '';
    let prop = '';
    const keyIdx = this.appI18n.indexOf('@@');
    if (keyIdx > 0) {
      const typeIdx = this.appI18n.indexOf('|');
      type = this.appI18n.substring(0, typeIdx);
      prop = this.appI18n.substring(typeIdx + 1, keyIdx);
    }
    switch (type) {
      case 'attr':
      case 'attribute':
        this.renderer.setAttribute(this.el.nativeElement, prop, value);
        break;
      default:
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', value);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.translate();
  }
}
