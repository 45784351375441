import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-poc',
  templateUrl: './feature-poc.component.html',
  styleUrls: ['./feature-poc.component.scss']
})
export class FeaturePocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
